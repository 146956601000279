import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { calendarOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { GetAppUserRights, GetAppReappointment } from '../data/api';
import useAuth from '../data/useAuth';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment, { utc } from 'moment'; 
import "react-big-calendar/lib/css/react-big-calendar.css";





const UserCalendar: React.FC = (props) => {

  const { user, setUser } = useAuth();
  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights(), {
    onSuccess: (data: any) => {
      if (data != 'A' && data != 'R') {
        setUser(null);
        window.location.href = '/tabs/login';
      }
    }
  });

  const [showToggle, setShowToggle] = useState(true);
  //const [showYear, setShowYear] = useState(new Date().getFullYear().toString());
  //const [showMonth, setShowMonth] = useState(new Date().getMonth().toString());
  const [events, setEvents] = useState([]);
 


  const queryAppointments = useQuery("qReappointments", () => GetAppReappointment(),
      {
        onSuccess: (data: any) => {
          if (data != null) {
            setEvents(data);
          }
        }
       });

  //useEffect(() => {
  //  queryAppointments.refetch();
  //}, [showMonth]);
  


  const localizer = momentLocalizer(moment);

  //const [eventList, setEventList] = useState(
  //  [
  //    {
  //      "title": "Robert Wang",
  //      "start": "2022-02-01T00:00:00.000Z",
  //      "end": "2022-02-01T00:00:00.000Z",
  //      "allDay": true
  //    }
  //  ]
  //);

  // console.log(eventList);



  return (
    <IonPage>

      <IonHeader >
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Reappointment Calendar</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        {queryUserRights.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>


          <IonCard color="lightyellow">
            <IonCardHeader >
              <IonItem lines="none" color="transparent">
                <IonCardTitle slot="start">Reappoint List</IonCardTitle>
                <IonBadge slot="end" onClick={() => setShowToggle(!showToggle)}>
                  <IonIcon icon={calendarOutline} color="white" size="large" />
                </IonBadge>
              </IonItem>
              <hr style={{ width: "100%", borderBottom: "1px solid black" }} />
            </IonCardHeader>
            <IonCardContent>
              The following list indicates when the terms of the serving members end.  You can click the calendar icon above
              to toggle the view of the reappointment dates.
            </IonCardContent>
          </IonCard>


        {showToggle &&
          (
            <div>
              <IonCard color="white">
                  <IonCardContent>
                    {queryAppointments.isSuccess && queryAppointments.data != null && queryAppointments.data.map((dataString: any) =>
                    (
                      <>
                        <IonItem color="transparent">
                          <IonLabel>
                            {dataString.resource}
                          </IonLabel>
                          <IonLabel>
                            {dataString.title}
                          </IonLabel>
                        </IonItem>
                      </>
                    )
                    )}
                  </IonCardContent>
                </IonCard>
            </div >
          )
        }
        {!showToggle &&
          (
            <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor='start'
                endAccessor='end'
                onNavigate={async (date) => {
                  //setShowYear(new Date(date).getFullYear().toString());
                  //setShowMonth(new Date(date).getMonth().toString());
                }
                }
                views={['month', 'week', 'day', 'agenda']}
                style={{ height: 600 }}
              />
            </div>
          )
          }

        </div>
      </IonContent>
    </IonPage>
  )
}



export default UserCalendar;
