import React, { useEffect, useReducer, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonButtons, IonCard, IonCardContent, IonSearchbar, useIonAlert, IonModal, IonCardHeader, IonCardTitle, IonInput, IonTextarea } from '@ionic/react';
import { closeOutline, personAddOutline } from 'ionicons/icons';
import './Generic.css';
import { Formik } from 'formik';
import { SendAppMessage } from '../data/api';


interface ModalAddProps {
  showBoard : any,
  showDesc: any,
  setShowModalEmail: any
}

const ModalAdd: React.FC<ModalAddProps> = ({ showBoard, showDesc,  setShowModalEmail }) => {

  const [present] = useIonAlert();
  


  return (<>
    <IonHeader >
      <IonToolbar color="tertiary">
        <IonTitle color="white">Sending Emails to Members of {showDesc}</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalEmail(false); }} color="transparent">
          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

      <Formik
        initialValues={{
          vSubject: null,
          vMessage: null
        }}


        onSubmit={async values => {
          const response = {
            BoardId: String(showBoard),
            Subject: values.vSubject,
            Message: values.vMessage
          }

          if (values.vMessage === null) {

            present({
              header: "Attention!",
              message: "Please enter the content of your message!",
              buttons: [
                {
                  text: "Ok"
                }
              ]
            })
           
          }
          else {
            const returnValue = await SendAppMessage(response);
            if (returnValue === "success") {

              present({
                header: "Attention!",
                message: "The message is successfully sent!",
                buttons: [
                  {
                    text: "Ok", handler: async () => {
                      setShowModalEmail(false);
                    }
                  }
                ]
              })
            }
            else {
              present("Sorry that we are unable to send the message due to the following error:" + returnValue);
            }

          }
         


        }}
      >
        {(formikProps: any) => (
          <form
            onSubmit={formikProps.handleSubmit}
            style={{ paddingBottom: "40px" }}
          >
            <p style={{ height: "0px" }}></p>
            <IonCard color="lightyellow">
              <IonCardHeader >
                <IonCardTitle>Attention</IonCardTitle>
                <hr style={{ width: "100%", borderBottom: "1px solid black" }} />
              </IonCardHeader>
              <IonCardContent>
                The message you are about to write will be sent to every active member of
                the  boards, commission or committe which you selected.
              </IonCardContent>
            </IonCard>
            <p style={{ height: "0px" }}></p>
            <IonCard>
              <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                <IonCardContent>
                  {/*Subject*/}
                  <IonItem>
                    <IonLabel position="floating">*Subject</IonLabel>
                    <IonInput
                      type="text"
                      name="vSubject"
                      onIonChange={formikProps.handleChange}
                      min="1"
                      max="30"
                      autocomplete="family-name"
                      value={formikProps.values.vSubject}
                      required={true}
                      autofocus
                    />
                  </IonItem>
                  
                  {/*Write Summary*/}
                  <p style={{ height: "10px" }}></p>
                  <IonItem lines="none">
                    <IonTextarea
                      rows={5}
                      minlength={1}
                      maxlength={1000}
                      name="vMessage"
                      placeholder="*Write your message here"
                      value={formikProps.values.vMessage}
                      onIonChange={formikProps.handleChange}></IonTextarea>
                  </IonItem>
                  
                </IonCardContent>


              </div>


            </IonCard>

            <p style={{ height: "0px" }}></p>
   
            {/*Submit Button*/}
              <div style={{ paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                <IonButton
                  color="success"
                  expand="block"
                  type="submit"
                  size="large"
                >Submit</IonButton>
              </div>
            
            
          </form>
        )}

      </Formik>

    </IonContent>
  </>);
}




export default ModalAdd;

