import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonItemGroup, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonSearchbar, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { ellipsisVerticalOutline, handLeftOutline, personAddOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserList, GetAppAvailabePositions, UpdateAppStaff, GetAppBoardDesc, GetAppUserRights } from '../data/api';
import ModalChoose from '../components/ModalChoose';
import { GetDailyCode } from '../data/utilities';
import useAuth from '../data/useAuth';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserAddUser: React.FC<GenericProps> = ({ match, title }) => {


  const { user, setUser } = useAuth();
  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights(), {
    onSuccess: (data: any) => {
      if (data != 'A') {
        window.location.href = '/tabs/usermenu';
      }
    }
  });
  
  const [present] = useIonAlert();
  const [showWord, setShowWord] = useState <string> ("");
  const [showTemp, setShowTemp] = useState("");
  const [showSelUser, setShowSelUser] = useState("");
  const [showSelUserName, setShowSelUserName] = useState("");
  const [showSelRole, setShowSelRole] = useState("");


  const [showModalChoose, setShowModalChoose] = useState(false);
  const [showBoardIds, setShowBoardIds] = useState<string|null>(null);
  const [showBoardIdsnm, setShowBoardIdsnm] = useState<string|null>(null);

  /*const qSearchList = useQuery("qSearchList2", () => GetAppUserList(showWord));*/

  const qSearchList = useMutation(() => GetAppUserList(showWord));

  

  const queryOpenPostions = useQuery("qOpenPostions", () => GetAppAvailabePositions(1));
  const queryBoards = useQuery("qBoards2", () => GetAppBoardDesc(GetDailyCode(null), showBoardIds===null?  "" : showBoardIds.toString()), {
    onSuccess: (data: any) => {
      if (data != null) { setShowBoardIdsnm(data); }
    }
  });



  function Reset() {
    setShowWord("");
    setShowTemp("");
    setShowSelUser("");
    setShowSelUserName("");
    setShowBoardIds("");
    setShowSelRole("");
    qSearchList.mutate();
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Add Staff / Council Member </IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        {qSearchList.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
          <IonCard color="lightyellow">
            <IonCardHeader>
              <IonCardTitle>Instruction</IonCardTitle>
              <hr style={{ width: "100%", borderBottom: "1px solid black" }} />
            </IonCardHeader>
            <IonCardContent>
             
              Here you can add a staff member or a council member which will server on the Boards, Commissions & Committees.
              However, you cannot add a citizen who is selected to serve on the board.  That person has to go through the
              application process.
              <p style={{ height: "5px" }}></p>
            </IonCardContent>
          </IonCard>
          <p style={{ height: "0px" }}></p>
          <IonCard>
            <IonCardHeader color="lightblue">
              <IonCardTitle><IonIcon icon={handLeftOutline} size="large" /> Follow the 3-Step Process Below</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p style={{ height: "10px" }}></p>

              {/*Step 1* ------------------------------------------------------------------------------*/}
              <IonItem color="favorite">
                <IonLabel style={{ fontSize: '20px' }}>1. Select the Person - ({showSelUserName})</IonLabel>
              </IonItem>
              {/*Searching Bar -------------------------------------------------------------------------*/}
              <IonItem  lines="none">
                <IonSearchbar showClearButton="always"
                  onIonChange={(e) => { setShowWord(e.detail.value!); qSearchList.mutate(); }}
                 // onKeyUp={e => CheckKeyEvent(e.key!)}
                  onIonClear={e => Reset()} />
                
              </IonItem>
              <div style={{ paddingLeft: '16%' }} >
                <IonList>
                { qSearchList.isSuccess && qSearchList.data != null && qSearchList.data.map((dataString: any) =>
                (
                  <>
                    <IonItem lines="none" href="javascript:void(0);" key={dataString.userid}
                      onClick={() => { setShowSelUser(dataString.userid); setShowSelUserName(dataString.username); setShowWord("") }} >
                        <IonIcon slot="start" icon={personAddOutline} size="large" color="primary" />
                        <IonLabel>
                          {dataString.username}
                        </IonLabel>
                        <IonLabel>
                          {dataString.usertitle}
                      </IonLabel>
                    </IonItem>
                  </>
                ))
                 }
                  </IonList>
              </div >

             
              {showSelUserName != "" && (<>
               

                {/*Step 2 * ------------------------------------------------------------------------------*/}
                <IonItem color="favorite">
                  <IonLabel style={{ fontSize: '20px' }}>2. Select the Board - ({showBoardIds})</IonLabel>
                </IonItem>

                <IonItem>
                  <IonLabel slot="start" class="FixedWidth">*Apply For</IonLabel>
                  <IonText>
                    {showBoardIdsnm}
                  </IonText>
                  <IonButtons slot="end" onClick={() => setShowModalChoose(true)} color="transparent">
                    <IonButton>
                      <IonIcon icon={ellipsisVerticalOutline} />
                    </IonButton>
                  </IonButtons>
                </IonItem>



                {/*Step 3* ------------------------------------------------------------------------------*/}
                {showBoardIds != "" &&
                  (
                  <>
                    <IonItem color="favorite">
                      <IonLabel style={{ fontSize: '20px' }}>3. Select the Playing Role {showSelRole}</IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <IonLabel slot="start" class="FixedWidth">&nbsp;</IonLabel>
                      <IonText slot="end">
                      <div className="select" style={{ textAlign: 'right' }}>
                        <select id="vBcposition"
                          onChange={(e) => {
                            setShowSelRole(e.target.value);

                          }
                          }
                          value={showSelRole} >

                          <option value="" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Chairman">Chairman</option>
                            <option value="Clerical">Clerical</option>
                            <option value="Council Member">Council Member</option>
                            <option value="Representative">Representative</option>
                            <option value="Staff">Staff</option>
                        </select>
                        <span className="focus"></span>
                        </div>
                        </IonText>
                    </IonItem>

                    {showSelUser != "" && showBoardIds !=null && showBoardIds != "" && showSelRole != null && showSelRole != "" &&
                      (
                      <>
                        <div style={{ paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>

                          <IonButton
                            color="danger"
                            expand="block"
                            onClick={
                              async () => {

                                const response = {
                                  userId: showSelUser,
                                  boardid: String(showBoardIds),
                                  position: showSelRole
                                }

                                const returnValue = await UpdateAppStaff(response);

                                if (returnValue == "success") {

                                  present({
                                    header: "Attention!",
                                    message: "You have successfully added the person to the board!",
                                    buttons: [
                                      {
                                        text: "Ok", handler: async () => {
                                          window.location.href = "/tabs/useradduser/";
                                        }
                                      }
                                    ]
                                  })

                                }
                                else {
                                  present("Sorry that we are unable to process your request due to the following error:" + returnValue);
                                }


                              }
                            }
                            size="large"
                          >Submit</IonButton>

                        </div>
                      </>
                      )
                    }
                   


                 </>
                  )
                }

              </>)
              }
              


            

              <IonModal isOpen={showModalChoose}
                backdropDismiss={false}
                onDidDismiss={() => {
                  setShowModalChoose(false); queryBoards.refetch();
                }}>
                <ModalChoose
                  qBoardList={queryOpenPostions}
                  bMultiple={false}
                  setShowBoardIds={setShowBoardIds}
                  setShowModalChoose={setShowModalChoose} >
                </ModalChoose>
              </IonModal>



            </IonCardContent>
          </IonCard>




        </div>
      </IonContent>

    </IonPage>
  )
}



export default UserAddUser;

