import { IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { calendarOutline, eyeOutline, keyOutline, personAddOutline,receiptOutline,searchOutline,settingsOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GetAppUserRights} from '../data/api';
import useAuth from '../data/useAuth';



const UserMenu: React.FC = (props) => {

  const { user, setUser } = useAuth();
  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights(), {
    onSuccess: (data: any) => {
      if (data != 'A' && data != 'R') {
        setUser(null);
        window.location.href = '/tabs/login';
      }
    }
  });




  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Main Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        {queryUserRights.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>

          <IonCard color="lightyellow">
            <IonCardContent>
              <IonItem lines="none" color="transparent">
                This area is strictly for internal use. All the information here is confidential and
                please do not share it with unrelated parties.
              </IonItem>
            </IonCardContent>
          </IonCard>

        {queryUserRights.isSuccess && (
          <>
              <IonCard>
                <IonCardContent>

                    <IonItem lines="none" href="/tabs/usersettings">
                      <IonIcon slot="start" icon={settingsOutline} size="large" color="primary" />
                      <IonLabel>
                        Settings
                      </IonLabel>
                    </IonItem>

                    <IonItem lines="none" href="/tabs/useradduser">
                      <IonIcon slot="start" icon={personAddOutline} size="large" color="primary" />
                      <IonLabel>
                        Add Staff / Council Member
                      </IonLabel>
                    </IonItem>

                    <IonItem lines="none" href="/tabs/userviewboards">
                      <IonIcon slot="start" icon={eyeOutline} size="large" color="primary" />
                      <IonLabel>
                        View Members of Boards, Commissions & Committees
                      </IonLabel>
                    </IonItem>

                    <IonItem lines="none" href="/tabs/usermembers">
                      <IonIcon slot="start" icon={searchOutline} size="large" color="primary" />
                      <IonLabel>
                        Search Members of Boards, Commissions & Committees
                      </IonLabel>
                  </IonItem>


                  <IonItem lines="none" href="/tabs/usercalendar">
                    <IonIcon slot="start" icon={calendarOutline} size="large" color="primary" />
                    <IonLabel>
                      Reappointment Calendar
                    </IonLabel>
                  </IonItem>


                    {queryUserRights.isSuccess && queryUserRights.data === "A" &&
                      (<>

                    <IonItem lines="none" href="/tabs/userlog">
                      <IonIcon slot="start" icon={receiptOutline} size="large" color="primary" />
                        <IonLabel>
                          System Change Log
                        </IonLabel>
                      </IonItem>

                        <IonItem lines="none" href="/tabs/useraccess">
                          <IonIcon slot="start" icon={keyOutline} size="large" color="primary" />
                          <IonLabel>
                            Grant Access
                          </IonLabel>

                        </IonItem>
                      </>
                      )
                    }



                </IonCardContent>
              </IonCard>
            
          </>
        )}
        </div>

      </IonContent>
    </IonPage>
  )
}



export default UserMenu;
