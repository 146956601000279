import { IonButton, IonIcon, IonItem, useIonAlert } from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';
import React from 'react';
import { UploadAppDoc, UploadAppDoc2 } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import './Generic.css';
import useAuth from '../data/useAuth';

interface CameraProps {
  useremail: any;
  setRefresh: any;
}

const Camera: React.FC<CameraProps> = ({ useremail, setRefresh }) => {


  let emailaddress = useremail;
  const { user, setUser } = useAuth();
  const [present] = useIonAlert();
  const [file, setFile] = React.useState<any>("");

  // Handles file upload event and updates state
  const handleUpload = (event: any) => {
    setFile(event.target.files[0]);
  }



  const handleFileSubmission = (event: any) => {

    if (file != "") {
      setRefresh(false);
      if (file.name.split('.').pop().toLowerCase() == "pdf" || file.name.split('.').pop().toLowerCase() == "jpg" || file.name.split('.').pop().toLowerCase() == "jpeg") {
        let source = 0;
        const formData = new FormData();
        if (user === null || user === '' || user===false) {
          formData.append("dailycode", GetDailyCode(emailaddress));
          formData.append("user", '');
        }
        else {
          formData.append("dailycode", "");
          formData.append("user", user);
          source = 1;
        }
        formData.append("email", emailaddress);
        formData.append("fileName", file.name);
        formData.append("formFile", file);


        let myPromise = new Promise(function (myResolve, myReject) {
          if (source===0)
            UploadAppDoc(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));
          else
            UploadAppDoc2(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));

        });
        myPromise.then(
          function (value: any) { setRefresh(true); },
          function (error: any) { present(error); }
        );

      }
      else {
        present("We only accept PDF file or JPG file!");
      }



    }
    else {
      present("Choose the file first!");
    }
  }

  return (<>
    <IonItem color="transparent" >
      <div>
        <IonIcon icon={cloudUpload} size="large" slot="start"></IonIcon> &nbsp; &nbsp;
        If you want to attach any files with your application, you can upload them here.
        Click [Choose File] first, then click [Upload]. We only accept the files in the
        following format [PDF and JPG].
      </div>
    </IonItem>
    <IonItem lines="none" color="transparent" >
      <input type="file" onChange={handleUpload} accept="application/pdf, image/jpeg" />
      <IonButton style={{ height: '40px', width: '180px' }}
        slot="end" color="primary"
        onClick={handleFileSubmission} ><strong>upload</strong>
      </IonButton>
    </IonItem>
  </>)
}


export default Camera;

