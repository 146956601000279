import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle,  IonContent,  IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonModal, IonPage,  IonTitle, IonToolbar } from '@ionic/react';
import {  pencilOutline, returnDownBackOutline } from 'ionicons/icons';
import React, {  useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppSetting, GetAppSettingsList, GetAppUserRights } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import ModalSetting from '../components/ModalSetting';

interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserSettings: React.FC<GenericProps> = ({ match, title }) => {

  
  const [showModalSetting, setShowModalSetting] = useState(false);
  const [recordIndex, setRecordIndex] = useState(0);
  const [showDesc, setDesc] = useState(null);
  const [showMemberSize, setMemberSize] = useState(0);
  const [showTermLimit, setTermLimit] = useState(0);
  const [showTermLen, setTermLen] = useState(0);
  const [showAppointBy, setAppointBy] = useState(null);
  const [showEmailYN, setEmailYN] = useState(null);
  const [showStatus, setStatus] = useState(null);


  const code = GetDailyCode(null);
  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights());
  const querySettingList = useQuery("qSettings", () => GetAppSettingsList(code));
  const querySetting = useQuery("qSetting", () => GetAppSetting(recordIndex), {
    onSuccess: (data: any) => {
      data.map((dataString: any) => {
        setDesc(dataString.bcname);
        setMemberSize(dataString.bcmembers);
        setTermLimit(dataString.bctermlimt);
        setTermLen(dataString.bctermlen);
        setAppointBy(dataString.bcaptby);
        setEmailYN(dataString.bcemailyn);
        setStatus(dataString.bcstatus0);
      })
     }
  });




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Boards & Commissions Settings</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
          <IonList>
            {queryUserRights.isSuccess && queryUserRights.data != null &&
              (<>
              {querySettingList.isSuccess && querySettingList.data != null && querySettingList.data.map((dataString: any) =>
              (
                <>
                  <IonCard id={dataString.bcindex} href="javascript:void(0);">
                    <IonCardHeader color="lightblue">
                      <IonItem color="transparent" lines="none"
                        onClick={
                          () => {
                            setRecordIndex(dataString.bcindex);
                            new Promise(resolve => setTimeout(resolve, 0)).then(() => { querySetting.refetch().then(() => setShowModalSetting(true)); querySettingList.refetch(); })
                          }
                        }
                        >
                        <IonCardTitle>{dataString.bcname}</IonCardTitle>
                        {queryUserRights.data === "A" && (<>
                          <IonBadge color="primary" slot="end"><IonIcon icon={pencilOutline} color="white" size="large" /></IonBadge>
                        </>)}   
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonLabel>Max # of Members </IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.bcmembers}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Active Members </IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.servingMembers}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Length of Term (Year)</IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.bctermlen}</IonLabel>
                      </IonItem>
                     
                      <IonItem>
                        <IonLabel>Appointed By</IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.bcaptby}</IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonLabel>Members Receiving Application Notifications</IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.bcemailyn == "Y" ? "Yes" : "No" }</IonLabel>
                      </IonItem>


                      <IonItem lines="none">
                        <IonLabel>Status</IonLabel>
                        <IonLabel style={{ textAlign: 'right' }}>{dataString.bcstatus0 === "A" ? "Active" : "Inactive"}</IonLabel>
                      </IonItem>
                      {dataString.servingMembers > dataString.bcmembers && (
                        <>
                          <IonItem lines="none">
                            <IonLabel color="danger">*Alert: Active members exceed the max limit.</IonLabel>
                          </IonItem>
                        </>
                      )}
                    </IonCardContent>
                  </IonCard>

                </>
              ))
              }
            </>)
            }         
       </IonList>
       </div>
      </IonContent>

      <IonModal isOpen={showModalSetting} backdropDismiss={false} onDidDismiss={() => { setShowModalSetting(false); querySettingList.refetch(); }}>
        <ModalSetting
          pindex={recordIndex}
          pbcname={showDesc}
          pbcmembers={showMemberSize}
          pbctermlen={showTermLen}
          pbctermlimt={showTermLimit}  
          pbcaptby={showAppointBy}
          pbcemailyn={showEmailYN}
          pbcstatus0={showStatus}
          setShowModalSetting={setShowModalSetting} ></ModalSetting>
      </IonModal>

    </IonPage>
  )
}



export default UserSettings;
