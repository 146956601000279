import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonList, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { Formik } from 'formik';
import { documentAttachOutline, extensionPuzzleOutline, eyeOutline, fileTrayFullOutline, receiptOutline, refreshOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserDetails, GetAppAvailabePositions, UpdateDetails, GetAppUserRights, GetAppDocList, DeleteAppUser } from '../data/api';
import { GetDailyCode, showfile } from '../data/utilities';
import { UpdateProfileSchema } from '../data/validations';
import ModalUpload from '../components/ModalUpload';
import { useHistory } from "react-router-dom";


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ id: string }> { }
interface GenericProps extends RouteProps, StateProps { }

const UserViewDetails: React.FC<GenericProps> = ({ match, title }) => {

  let history = useHistory();

  const [present] = useIonAlert();
  const [presentWait, dismiss] = useIonLoading();
  const [showRefreshFiles, setRefreshFiles] = useState(false);

  const queryDetails = useQuery("qUserDetails", () => GetAppUserDetails(match.params.id));
  const queryOpenPostions = useQuery("qOpenPostions", () => GetAppAvailabePositions(1));
  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights());


  const queryFiles = useQuery("qFilesX", () => GetAppDocList({ dailycode: GetDailyCode(null), userid: match.params.id }), {
    onSuccess: (data: any) => {
      setRefreshFiles(false);
    }
  });

  const deleteUserMutation = useMutation((data: any) => DeleteAppUser(data));





  useEffect(() => {
    queryFiles.refetch();

  }, [showRefreshFiles]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }} >User Profile</IonTitle>
          <IonButtons slot="end" onClick={() => history.goBack()} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
          {queryDetails.isLoading && (
            <IonCard>
              <IonCardContent>
                <IonItem lines="none">
                  Loading...
                </IonItem>
              </IonCardContent>
            </IonCard>
          )}

          {queryDetails && queryDetails.data && queryDetails.data != null &&
            (
              <>
                <IonCard>

                  <IonCardContent>

                    <IonList>
                      {queryDetails && queryDetails.data && queryDetails.data != null &&
                        (
                          <>

                            <Formik
                              initialValues={{
                                vBcfirstnam: queryDetails.data.bcfirstnam,
                                vBclastnam: queryDetails.data.bclastnam,
                                vBcaddress1: queryDetails.data.bcaddress1,
                                vBcaddress2: queryDetails.data.bcaddress2,
                                vBccity: queryDetails.data.bccity,
                                vBcstate: queryDetails.data.bcstate,
                                vBczipcode: queryDetails.data.bczipcode,
                                vBcphonenum: queryDetails.data.bcphonenum,
                                vBctextmsg: queryDetails.data.bctextmsg,
                                vBcemail: queryDetails.data.bcemail,
                                vBcbrdcomid: queryDetails.data.bcbrdcomid,
                                vBcposition: queryDetails.data.bcposition,
                                vBcapointed: queryDetails.data.bcapointed,
                                vBcsummary: queryDetails.data.bcsummary,
                                vBcnotes: queryDetails.data.bcnotes,
                                vBcstatus0: queryDetails.data.bcstatus0

                              }}

                              validationSchema={UpdateProfileSchema}


                              onSubmit={async values => {
                                const response = {
                                  dailycode: GetDailyCode(values.vBcemail),
                                  Bcmemberid: String(queryDetails.data.bcmemberid),
                                  Bcfirstnam: values.vBcfirstnam.trim(),
                                  Bclastnam: String(values.vBclastnam).trim(),
                                  Bcaddress1: String(values.vBcaddress1).trim(),
                                  Bcaddress2: String(values.vBcaddress2).trim(),
                                  Bccity: String(values.vBccity).trim(),
                                  Bcstate: values.vBcstate.trim(),
                                  Bczipcode: String(values.vBczipcode).trim(),
                                  Bcphonenum: String(values.vBcphonenum).trim(),
                                  Bctextmsg: String(values.vBctextmsg).trim(),
                                  Bcemail: values.vBcemail.trim(),
                                  Bcbrdcomid: String(values.vBcbrdcomid).trim(),
                                  Bcposition: String(values.vBcposition).trim(),
                                  Bcsummary: String(values.vBcsummary).trim(),
                                  Bcapointed: String(values.vBcapointed).trim(),
                                  Bcnotes: String(values.vBcnotes).trim(),
                                  Bcstatus0: values.vBcstatus0.trim()
                                }



                                const returnValue = await UpdateDetails(response);

                                if (returnValue == "success") {

                                  queryDetails.refetch().then(() => window.history.back());
                                  //presentWait({ message: "Please wait...", duration: 500 });
                                  
                                }
                                else {
                                  present("Sorry that we are unable to update the profile due to the following error:" + returnValue);
                                }

                              }}
                            >



                              {(formikProps: any) => (
                                <form
                                  onSubmit={formikProps.handleSubmit}
                                  style={{ paddingBottom: "40px" }}
                                >
                                  <IonCard color="lightyellow">
                                    <IonCardHeader>
                                      <IonCardTitle>Instruction</IonCardTitle>
                                      <hr style={{ width: "100%", borderBottom: "1px solid black" }} />
                                    </IonCardHeader>
                                    <IonCardContent>

                                      You can edit the records only if you have administrator rights! If the profile is of a member/chairman,
                                      you cannot modify the following fields for person: email address, position he applied and the reason for
                                      serving the postion. Non-administrator can only update status field.
                                      <p style={{ height: "5px" }}></p>

                                    </IonCardContent>
                                  </IonCard>
                                  <p style={{ height: "0px" }}></p>
                                  <IonCard>
                                    <IonCardHeader color="lightblue">
                                      <IonItem color="transparent" lines="none">
                                        <IonAvatar >
                                          {queryDetails.data.bcphoto === null ?
                                            (
                                              <>
                                                <img src='./assets/img/avatar.png' alt="employee" />
                                              </>
                                            )
                                            :
                                            (
                                              <img src={queryDetails.data.bcphoto} alt="employee" />
                                            )
                                          }

                                        </IonAvatar>
                                        <IonCardTitle>&nbsp; Personal Information</IonCardTitle>
                                      </IonItem>
                                    </IonCardHeader>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                                      <IonCardContent>
                                        {/*Last*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth" >*Last Name</IonLabel>
                                          <IonInput
                                            type="text"
                                            name="vBclastnam"
                                            onIonChange={formikProps.handleChange}
                                            min="1"
                                            max="30"
                                            placeholder="*Last name"
                                            autocomplete="family-name"
                                            value={formikProps.values.vBclastnam}
                                            autofocus={true}
                                            required={true}
                                          />

                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBclastnam && formikProps.errors.vBclastnam}
                                        </IonLabel>
                                        {/*First*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth" >*First Name</IonLabel>
                                          <IonInput
                                            type="text"
                                            name="vBcfirstnam"
                                            onIonChange={formikProps.handleChange}
                                            min="1"
                                            max="30"
                                            placeholder="*First name"
                                            autocomplete="given-name"
                                            value={formikProps.values.vBcfirstnam}
                                            required={true}
                                          />
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBcfirstnam && formikProps.errors.vBcfirstnam}
                                        </IonLabel>
                                        {/*Address*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Address</IonLabel>
                                          <IonItemGroup>
                                            <IonInput
                                              type="text"
                                              name="vBcaddress1"
                                              onIonChange={formikProps.handleChange}
                                              min="1"
                                              max="50"
                                              placeholder="*Address 1"
                                              autocomplete="on"
                                              value={formikProps.values.vBcaddress1}
                                              required={false}
                                            />
                                            <IonInput
                                              type="text"
                                              name="vBcaddress2"
                                              onIonChange={formikProps.handleChange}
                                              min="1"
                                              max="50"
                                              placeholder="Address 2 (Optional)"
                                              autocomplete="on"
                                              value={formikProps.values.vBcaddress2}
                                              required={false}
                                            />
                                          </IonItemGroup>
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBcaddress1 && formikProps.errors.vBcaddress1}
                                        </IonLabel>
                                        {/*City*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*City</IonLabel>
                                          <IonInput
                                            type="text"
                                            name="vBccity"
                                            onIonChange={formikProps.handleChange}
                                            min="1"
                                            max="100"
                                            autocomplete="on"
                                            placeholder="*City"
                                            value={formikProps.values.vBccity}
                                            required={true}
                                          />
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBccity && formikProps.errors.vBccity}
                                        </IonLabel>
                                        {/*State*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*State</IonLabel>
                                          <div className="select">
                                            <select id="HASTATE"
                                              onChange={(e) => {
                                                formikProps.values.vBcstate = e.target.value;
                                                formikProps.handleChange(e);
                                              }}
                                              value={formikProps.values.vBcstate}>
                                              <option value='AL'>Alabama</option>
                                              <option value='AK'>Alaska</option>
                                              <option value="AZ">Arizona</option>
                                              <option value="AR">Arkansas</option>
                                              <option value="CA">California</option>
                                              <option value="CO">Colorado</option>
                                              <option value="CT">Connecticut</option>
                                              <option value="DE">Delaware</option>
                                              <option value="DC">District of Columbia</option>
                                              <option value="FL">Florida</option>
                                              <option value="GA">Georgia</option>
                                              <option value="HI">Hawaii</option>
                                              <option value="ID">Idaho</option>
                                              <option value="IL">Illinois</option>
                                              <option value="IN">Indiana</option>
                                              <option value="IA">Iowa</option>
                                              <option value="KS">Kansas</option>
                                              <option value="KY">Kentucky</option>
                                              <option value="LA">Louisiana</option>
                                              <option value="ME">Maine</option>
                                              <option value="MD">Maryland</option>
                                              <option value="MA">Massachusetts</option>
                                              <option value="MI">Michigan</option>
                                              <option value="MN">Minnesota</option>
                                              <option value="MS">Mississippi</option>
                                              <option value="MO">Missouri</option>
                                              <option value="MT">Montana</option>
                                              <option value="NE">Nebraska</option>
                                              <option value="NV">Nevada</option>
                                              <option value="NH">New Hampshire</option>
                                              <option value="NJ">New Jersey</option>
                                              <option value="NM">New Mexico</option>
                                              <option value="NY">New York</option>
                                              <option value="NC">North Carolina</option>
                                              <option value="ND">North Dakota</option>
                                              <option value="OH">Ohio</option>
                                              <option value="OK">Oklahoma</option>
                                              <option value="OR">Oregon</option>
                                              <option value="PA">Pennsylvania</option>
                                              <option value="PR">Puerto Rico</option>
                                              <option value="RI">Rhode Island</option>
                                              <option value="SC">South Carolina</option>
                                              <option value="SD">South Dakota</option>
                                              <option value="TN">Tennessee</option>
                                              <option value="TX">Texas</option>
                                              <option value="UT">Utah</option>
                                              <option value="VT">Vermont</option>
                                              <option value="VA">Virginia</option>
                                              <option value="WA">Washington</option>
                                              <option value="WV">West Virginia</option>
                                              <option value="WI">Wisconsin</option>
                                              <option value="WY">Wyoming</option>
                                            </select>
                                            <span className="focus"></span>
                                          </div>
                                        </IonItem>
                                        {/*Zip*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Zip Code</IonLabel>
                                          <IonInput
                                            type="text"
                                            name="vBczipcode"
                                            onIonChange={formikProps.handleChange}
                                            maxlength={5}
                                            autocomplete="postal-code"
                                            value={formikProps.values.vBczipcode}
                                            required={true}
                                          />
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBczipcode && formikProps.errors.vBczipcode}
                                        </IonLabel>
                                        {/*Phone*/}
                                        <IonItem lines="none">
                                          <IonLabel slot="start" class="FixedWidth">*Phone #</IonLabel>
                                          <IonInput
                                            name="vBcphonenum"
                                            type="text"
                                            placeholder="*Phone #"
                                            autocomplete="on"
                                            maxlength={10}
                                            value={formikProps.values.vBcphonenum}
                                            onIonChange={formikProps.handleChange}
                                            required={true}
                                          />
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBcphonenum && formikProps.errors.vBcphonenum}
                                        </IonLabel>
                                        {/*Phone Text Choice*/}

                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Accept texting?</IonLabel>
                                          <div className="select">
                                            <select id="vBctextmsg"
                                              onChange={(e) => {
                                                formikProps.values.vBctextmsg = e.target.value;
                                                formikProps.handleChange(e);
                                              }
                                              }
                                              value={formikProps.values.vBctextmsg}  >

                                              <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                                              <option value="Y">Yes</option>
                                              <option value="N">No</option>
                                            </select>
                                            <span className="focus"></span>
                                          </div>

                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBctextmsg && formikProps.errors.vBctextmsg}
                                        </IonLabel>
                                        {/*Email*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Email</IonLabel>
                                          <IonInput
                                            type="text"
                                            name="vBcemail"
                                            onIonChange={(e) => {
                                              formikProps.values.vBcemail = e.detail.value;
                                              formikProps.handleChange(e);
                                            }}
                                            min="1"
                                            max="30"
                                            placeholder="*Email"
                                            autocomplete="family-name"
                                            value={formikProps.values.vBcemail}
                                            required={true}
                                            
                                          />
                                        </IonItem>
                                        <IonLabel color="danger" className="ion-padding">
                                          {formikProps.touched.vBcemail && formikProps.errors.vBcemail}
                                        </IonLabel>
                                      </IonCardContent>
                                    </div>
                                  </IonCard>
                                  <p style={{ height: "0px" }}></p>
                                  <IonCard>
                                    <IonCardHeader color="lightblue">
                                      <IonCardTitle><IonIcon icon={extensionPuzzleOutline} size="large" /> &nbsp; Boards/Commissions Positions</IonCardTitle>
                                    </IonCardHeader>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                                      <IonCardContent>
                                        {/*Serving Role*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Serving Role</IonLabel>
                                          <div className="select">
                                            <select id="vBcposition"
                                              onChange={(e) => {
                                                formikProps.values.vBcposition = e.target.value;
                                                formikProps.handleChange(e);
                                              }
                                              }
                                              value={formikProps.values.vBcposition}  >

                                              <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                                              <option value="Applicant">Applicant</option>
                                              <option value="Chairman">Chairman</option>
                                              <option value="Clerical">Clerical</option>
                                              <option value="Council Member">Council Member</option>
                                              <option value="Member">Member</option>
                                              <option value="Representative">Representative</option>
                                              <option value="Staff">Staff</option>
                                            </select>
                                            <span className="focus"></span>
                                          </div>

                                        </IonItem>
                                        {/*Apply For*/}
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Boards/Committee</IonLabel>
                                          <IonSelect
                                            interface="action-sheet"
                                            mode="ios"
                                            disabled={queryDetails.data.bcposition === "Member" ? true :
                                              queryDetails.data.bcposition === "Applicant" ? true :
                                                queryDetails.data.bcposition === "Chairman" ? true : false}
                                            value={formikProps.values.vBcbrdcomid}
                                            onIonChange={(e) => {
                                              formikProps.values.vBcbrdcomid = e.detail.value;
                                              formikProps.handleChange(e);

                                            }}
                                          >
                                            {queryOpenPostions && queryOpenPostions.data && queryOpenPostions.data.length > 0 && queryOpenPostions.data.map((dataString: any) =>
                                            (
                                              <IonSelectOption value={dataString.bcindex}>{dataString.bcname}</IonSelectOption>
                                            )
                                            )}
                                          </IonSelect>
                                        </IonItem>


                                        {(queryDetails.data.bcposition === "Member" || queryDetails.data.bcposition === "Chairman" || queryDetails.data.bcposition === "Applicant") &&
                                          <>

                                            {/*Appointed Date*/}
                                            <IonItem>
                                              <IonLabel slot="start" class="FixedWidth">Appointed Date</IonLabel>
                                              <IonInput
                                                type="text"
                                                name="vBcapointed"
                                                onIonChange={formikProps.handleChange}
                                                placeholder="*Date"
                                                autocomplete="family-name"
                                                value={formikProps.values.vBcapointed}
                                                required={false}
                                              />
                                            </IonItem>


                                            {/*Write Summary*/}
                                            <IonItem lines="none">
                                              <IonLabel slot="start" class="FixedWidth">Qualification</IonLabel>
                                            </IonItem>
                                            <IonItem lines="none">
                                                   <IonTextarea
                                                    rows={8}
                                                    readonly={true }
                                                   name="vBcsummary"
                                                   value={formikProps.values.vBcsummary}
                                          onIonChange={formikProps.handleChange}></IonTextarea>
                                            </IonItem>

                                          </>
                                        }

                                      </IonCardContent>
                                    </div>

                                  </IonCard>
                                  <p style={{ height: "0px" }}></p>
                                  {/*Office Notes*/}
                                  <IonCard>
                                    <IonCardHeader color="lightblue">
                                      <IonCardTitle><IonIcon icon={receiptOutline} size="large" /> &nbsp; Office Notes</IonCardTitle>
                                    </IonCardHeader>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                                      <IonCardContent>
                                        <IonItem lines="none">
                                          <IonTextarea
                                            rows={5}
                                            minlength={1}
                                            maxlength={1000}
                                            name="vBcnotes"
                                            value={formikProps.values.vBcnotes}
                                            onIonChange={formikProps.handleChange}></IonTextarea>
                                        </IonItem>
                                      </IonCardContent>


                                    </div>


                                  </IonCard>
                                  <p style={{ height: "0px" }}></p>
                                  {/*Record Info.*/}
                                  <IonCard>
                                    <IonCardHeader color="lightblue">
                                      <IonCardTitle><IonIcon icon={fileTrayFullOutline} size="large" /> &nbsp; Record Information</IonCardTitle>
                                    </IonCardHeader>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>


                                      <IonCardContent>
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">*Date/Time Stamp</IonLabel>
                                          <IonLabel aria-disabled={true}>{queryDetails.data.bcstamp}</IonLabel>
                                        </IonItem>
                                        <IonItem>
                                          <IonLabel slot="start" class="FixedWidth">Current Status</IonLabel>
                                          <div className="select">
                                            <select id="vBcstatus0"
                                              onChange={(e) => {
                                                formikProps.values.vBcstatus0 = e.target.value;
                                                formikProps.handleChange(e);
                                              }
                                              }
                                              value={formikProps.values.vBcstatus0}  >

                                              <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                                              <option value="A">Active (Serving)</option>
                                              <option value="H">Inactive</option>
                                              <option value="P">Pending</option>
                                              <option value="R">Recommended</option>
                                            </select>
                                            <span className="focus"></span>
                                          </div>

                                        </IonItem>
                                      </IonCardContent>


                                    </div>


                                  </IonCard>
                                  <p style={{ height: "0px" }}></p>
                                  {/*Attachment* ===================================================================================================================== */}
                                  <IonCard>
                                    <IonCardHeader color="lightblue">
                                      <IonItem color="transparent" lines="none">
                                        <IonCardTitle><IonIcon icon={documentAttachOutline} size="large" /> &nbsp; Attachment</IonCardTitle>
                                        <IonButtons slot="end" onClick={() => { queryFiles.refetch() }} >
                                          <IonIcon icon={refreshOutline} size="large" />
                                        </IonButtons>
                                      </IonItem>
                                    </IonCardHeader>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                                      <IonCardContent>
                                        <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                                          <div>
                                            <ModalUpload useremail={queryDetails.data.bcemail} setRefreshFiles={setRefreshFiles} />
                                          </div>
                                        </div>
                                        {queryFiles.isSuccess && queryFiles.data != null && queryFiles.data.map((dataString: any) =>
                                        (
                                          <>
                                            <IonItem key={dataString.bcimgidx}>
                                              <IonButtons slot="start" onClick={() => showfile(dataString.blob, dataString.ext, dataString.filename)}>
                                                <IonIcon icon={eyeOutline} size="large" color="primary" />
                                              </IonButtons>
                                              <IonLabel>{dataString.filename}</IonLabel >
                                              <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                                            </IonItem>

                                          </>
                                        ))}

                                      </IonCardContent>
                                    </div>
                                  </IonCard>


                                  <p style={{ height: "0px" }}></p>
                                  {queryUserRights.isSuccess && queryUserRights.data === 'A' &&
                                    <>
                                      <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <IonGrid >
                                          <IonRow>
                                            <IonCol>

                                              <IonButton
                                                color="success"
                                                expand="block"
                                                type="submit"
                                                size="large"
                                              >Save Changes
                                              </IonButton>
                                            </IonCol>
                                            <IonCol>

                                              <IonButton
                                                color="danger"
                                                expand="block"
                                                size="large"
                                                onClick={() => {
                                                  present({
                                                    header: "Attention!",
                                                    message: "Are you sure you want to delete this record?",
                                                    buttons: [
                                                      "Cancel",
                                                      {
                                                        text: "Yes", handler: () => {

                                                          var data = {
                                                            Bcpindex: match.params.id
                                                          }
                                                          deleteUserMutation.mutate(data);
                                                          presentWait({ message: "Delete ...", duration: 100 });
                                                        }
                                                      }
                                                    ]
                                                  })

                                                }
                                                }
                                              >Delete User
                                              </IonButton>
                                            </IonCol>
                                          </IonRow>
                                        </IonGrid>
                                      </div>
                                    </>
                                  }


                                  <p style={{ height: "0px" }}></p>


                                </form>
                              )}


                            </Formik>
                          </>
                        )
                      }
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </>
            )
          }


        </div>
      </IonContent>



    </IonPage>
  )
}



export default UserViewDetails;
