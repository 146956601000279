import { IonAccordion, IonCardHeader, IonCardTitle, IonLabel, IonRouterLink, IonSearchbar } from '@ionic/react';
import { IonAccordionGroup, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonList, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { calendarClearOutline, documentOutline, pencilOutline, personOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { GetAppUserRights, GetAppLog } from '../data/api';







const UserLog: React.FC = (props) => {

  const [showWord, setShowWord] = useState<string>("");
  const [showCategory, setShowCategory] = useState<string>("");



  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights());
  const queryLog = useQuery("qLog", () => GetAppLog(showCategory, showWord));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>System Log</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {queryLog.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        <IonCard color="lightblue">
          <IonCardHeader>
            <IonSearchbar showClearButton="always" placeholder="No implemented yet!"
              onIonChange={(e) => { setShowWord(e.detail.value!); }}
              onIonClear={e => alert()} />
          </IonCardHeader>
          <IonCardContent>
            <hr style={{ width: "100%", borderBottom: "1px solid white" }} />
            <IonItem color="transparent" lines="none">
              <IonLabel>Category</IonLabel>
              <div className="select">
                <select id="fCategory"
                  onChange={async (e) => {
                    setShowCategory(e.target.value);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    queryLog.refetch();
                  }
                  }>
                  <option value="0" >All</option>
                  <option value="Adding Staff Member">Adding Staff Member</option>
                  <option value="Deleting">Delete User</option>
                  <option value="Grant Access">Grant Access</option>
                  <option value="Login">Login</option>
                  <option value="Logout">Logout</option>
                  <option value="Modify Access">Modify Access</option>
                  <option value="New application filed">New application filed</option>
                  <option value="Password Chagne">Password Chagne</option>
                  <option value="Request Reset PWD">Request Reset PWD</option>
                  <option value="Sending Board Message">Sending Board Message</option>
                  <option value="Sending Email to Applicant">Sending Email to Applicant</option>
                  <option value="Settings - notification">Settings - notification</option>
                  <option value="Settings - Appointed By">Settings - Appointed By</option>
                </select>
                <span className="focus"></span>
              </div>
            </IonItem>
          </IonCardContent>
        </IonCard>

        {queryUserRights.isSuccess && queryUserRights.data === "A" &&
          (
            <IonAccordionGroup>
              {queryLog.isSuccess && queryLog.data != null && queryLog.data.map((dataString: any) =>
              (
                <>
                  <IonAccordion id={dataString.bcalindex}>
                    <IonItem slot="header" color="lightyellow">
                      <IonLabel>{dataString.bcalaction}</IonLabel>
                    </IonItem>
                    <IonList slot="content">
                      <IonItem>
                        <IonIcon icon={calendarClearOutline} slot="start" />
                        <IonLabel slot="end">{dataString.bcaltime}</IonLabel>
                      </IonItem>
                      {dataString.bcusername != null &&
                        (
                          <IonItem>
                            <IonIcon icon={personOutline} slot="start" />
                            <IonLabel slot="end" >{dataString.bcusername}</IonLabel>
                          </IonItem>
                        )
                      }

                      {dataString.bcalfrom != null && dataString.bcalfrom.trim() != "" &&
                        (
                          <IonItem>
                            <IonIcon icon={pencilOutline} slot="start" />
                            <IonLabel slot="end" >{dataString.bcalfrom} to {dataString.bcalto}</IonLabel>
                          </IonItem>
                        )
                      }
                      {dataString.bcalcomm != null &&
                        (
                          <IonItem>
                            <IonIcon icon={documentOutline} slot="start" />
                            {dataString.bcpindex > 0 ?
                              (<><IonRouterLink color="tertiary" href={'/tabs/userviewdetails/' + dataString.bcpindex} slot="end"><IonLabel>{dataString.bcalcomm}</IonLabel> </IonRouterLink></>)
                              :
                              (<><IonLabel slot="end" >{dataString.bcalcomm}</IonLabel></>)
                            }
                          </IonItem>
                        )
                      }



                    </IonList>
                  </IonAccordion>

                </>
              ))}
            </IonAccordionGroup>
          )
        }




      </IonContent>
    </IonPage>
  )
}



export default UserLog;

