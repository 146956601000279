import axios from 'axios';


export async function LogoutApp() {
  return axios
    .get("/api/LogoutServer")
    .then(res => res.data);
}

export async function ValidateUser(valEmail, valPassword) {
  return axios
    .post('/api/ValidateUser', { UserEmail: valEmail, UserPassword: valPassword })
    .then(res => res.data)
}

export async function IsAppUserAuthenticated(auth) {
  return axios
    .get("/api/IsUserAuthenticated")
    .then(res => res)
    .catch(err => { unauthorize(err, auth.setUser); window.location.href = '/tabs/login'; })
}

export async function GetAppAttachmentList(valIndex) {
  return axios
    .get("/api/GetAttachmentList?index=" + valIndex)
    .then(res => res.data);
}

export async function GetAppUserEmail() {
  return axios
    .get("/api/GetUserEmail")
    .then(res => res.data);
}

////=================================================================================================================================================
//// BC Functions
////=================================================================================================================================================

//  Unsecured area only =================================================
function unauthorize(err) {
  //console.log(err);
  // window.location.href = '/tabs/login';
  if (err.response.status === 404 || err.response.status === 401) {
     window.location.href = '/tabs/login';
  }
}
export async function VerifyGoogleKey(responseValue) {
  //    const data = {
  //      value: responseValue,
  //      code: codeValue
  //};
  //console.log(JSON.stringify(data));
  return axios
    .post('/api/VerifyGoogleResponse', {
      value: responseValue
    })
    .then(res => res.data)
}
export async function VerifyAppEmail(responseValue, resetValue) {
  return axios
    .post('/api/VerifyEmail', {
      value: responseValue,
      reset: resetValue
    })
    .then(res => res.data)
}
export async function GetAppAvailabePositions(option) {
  if (option === undefined) {option=-1}
  return axios
    .get("/api/GetAvailPositions/" + option)
    .then(res => res.data);
}
export async function SubmitApplication(data) {
 //console.log(JSON.stringify(data));
  var config = {
    method: 'post',
    url: '/api/Application/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function UploadAppDoc(valData) {

  return axios
    .post("/api/UploadDoc", valData, {
      headers: {
        'Content-Type': 'multipart/form-data or application/x-www-form-urlencoded'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}
export async function UploadAppDoc2(valData, source) {
  return axios
    .post("/api/UploadDoc2", valData, {
      headers: {
        'Content-Type': 'multipart/form-data or application/x-www-form-urlencoded'
      }
    })
    .then(res => res.data)
    .catch(err => err.data)
}
export async function GetAppDocList(data) {
  var url = "GetDocList2";
  if (data.userid.indexOf('@') > -1) { url = "GetDocList"}
  var config = {
    method: 'post',
    url: '/api/' + url +'/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
    .catch(err => err.data)
}
export async function DeleteAppImage(data) {
  var config = {
    method: 'post',
    url: '/api/DeleteImage/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export function GetAppBoardDesc(code, boards) {
  return axios
    .post("/api/getboarddesc", {
      dailycode: code,
      boardids: boards
    })
    .then(res => res.data);
}
// Authorized area only ==================================================
export async function GetAppSettingsList(data) {
  return axios
    .get("/api/GetSettings")
    .then(res => res.data);
}
export async function GetAppSetting(id) {
  return axios
    .get("/api/GetSetting?id="+id)
    .then(res => res.data)
    .catch(err => unauthorize(err))
}
export async function UpdateAppSetting(data) {
  var config = {
    method: 'post',
    url: '/api/UpdateSetting/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function GetAppMemberList(pstatus, pposition, pid, pword) {
  return axios
    .post('/api/GetMemberList', {
      status: pstatus,
      position: pposition,
      boardid: pid,
      keyword: pword
    })
    .then(res => res.data)
    .catch(err => unauthorize(err))
}
export async function GetAppEmailByUid(uidValue) {
  return axios
    .get('/api/GetEmailByUid/?uid=' + uidValue)
    .then(res => res.data)
}
export async function GetAppUserRights() {
  return axios
    .get('/api/GetUserRights')
    .then(res => res.data)
    .catch(err => unauthorize(err))
}
export async function UpdateAppUserPwd(keyValue, emailValue, pwdValue) {
  return axios
    .post('/api/UpadePwd', {
      key: keyValue,
      email: emailValue,
      pwd: pwdValue
    })
    .then(res => res.data)
}
export async function GetAppAccessList() {
  return axios
    .get('/api/GetAccessList')
    .then(res => res.data)
}
export async function UpdateAppAccess(data) {
  var config = {
    method: 'post',
    url: '/api/UpdateAccess/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function GetAppUserList(searchtext) {
  return axios
    .get('/api/GetUserList/' + searchtext)
    .then(res => res.data)
}
export async function GetAppUserDetails(pid) {
  return axios
    .post('/api/GetUserDetail/' +pid )
    .then(res => res.data)
}
export async function UpdateDetails(data) {
  //console.log(JSON.stringify(data));
  var config = {
    method: 'post',
    url: '/api/UpdateDetail/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function UpdateAppStaff(data) {
  //console.log(JSON.stringify(data));
  var config = {
    method: 'post',
    url: '/api/UpdateStaff/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function GetAppOperationHours() {
  return axios
    .get('/api/GetOperationHours/')
    .then(res => res.data)
}
export async function GetAppLog(pcategory, pkeyword) {
  return axios
    .post('/api/Log', {
      category: pcategory,
      keyword: pkeyword
    })
    .then(res => res.data)
    .catch(err => unauthorize(err))
}
export async function SendAppMessage(data) {
  //console.log(JSON.stringify(data));
  var config = {
    method: 'post',
    url: '/api/SendMessage/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}
export async function GetAppReappointment(pyear, pmonth) {
  return axios
    .get('/api/Reappointment/')
    .then(res => res.data)
}
export async function DeleteAppUser(data) {
  var config = {
    method: 'post',
    url: '/api/DeleteUser/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };
  return axios(config)
    .then(res => res.data)
}



