import * as yup from 'yup';


export const LoginSchema = yup.object({

  email: yup
    .string()
    .email('*Invalid email')
    .required('*Required'),
  password: yup.string()
    .required('*Required.')
});


export const PasswordSchema = yup.object({

  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: password => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match")
    })

});

export const ApplicationSchema = yup.object({

  vBcfirstnam: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBclastnam: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcaddress1: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBccity: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBczipcode: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcphonenum: yup
    .string()
    .nullable()
    .matches(/\d{10}/, "Must only contain 10 digits (i.e. 8012297007)")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits.")
    .required('*Required.'),
  vBctextmsg: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcemail: yup
    .string()
    .email('*Invalid email')
    .required('*Required'),
  //vBcbrdcomids: yup
  //  .array()
  //  .nullable()
  //  .required('*Required.'),
  vBcsummary: yup
    .string()
    .nullable()
    .required('*Required.')

});

export const UpdateProfileSchema = yup.object({

  vBcfirstnam: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBclastnam: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcaddress1: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBccity: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBczipcode: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcphonenum: yup
    .string()
    .nullable()
    .matches(/\d{10}/, "Must only contain 10 digits (i.e. 8012297007)")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits.")
    .required('*Required.'),
  vBctextmsg: yup
    .string()
    .nullable()
    .required('*Required.'),
  vBcemail: yup
    .string()
    .email('*Invalid email')
    .required('*Required'),
  vBcsummary: yup
    .string()
    .nullable()

});

//export const EmailSchema = yup.object({

//  vSubject: yup
//    .string()
//    .nullable()
//    .required('*Required.'),
//  vMessage: yup
//    .string()
//    .nullable()
//    .required('*Required.'),


//});
