import React  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { homeOutline,  personRemoveOutline, personOutline, settingsOutline, keyOutline, exitOutline } from 'ionicons/icons';
import Login from './Login';
import useAuth from '../data/useAuth';
import Application from './Application';
import UserMenu from './UserMenu';
import UserSettings from './UserSettings';
import UserMember from './UserMember';
import UserAccess from './UserAccess';
import ResetPwd from './ResetPwd';
import ChangePassword from './ChangePassword';
import UserViewDetails from './UserViewDetails';
import UserAddUser from './UserAddUser';
import UserViewBoards from './UserViewBoards';
import UserCalendar from './UserCalendar';
import UserLog from './UserLog';
import { LogoutApp } from '../data/api';


interface MainTabsProps { }

const MainTabs: React.FC<MainTabsProps> = () => {
  const auth = useAuth();
  const loggedIn: boolean = auth.user ? true : false;

  function ExistApp() {
    setUser(false);
    LogoutApp();
  }


  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/application" />
       

        <Route path="/tabs/usermenu"
          render={() => (
            loggedIn ? (
              <UserMenu />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/usersettings"
          render={(props: any) => (
            loggedIn ? (
              <UserSettings
                {...props}
              />
            ) : (
                <Login setUser={auth.setUser} />
            )
          )} />


        <Route path="/tabs/useradduser"
          render={(props: any) => (
            loggedIn ? (
              <UserAddUser
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/usermembers"
          render={(props: any) => (
            loggedIn ? (
              <UserMember
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/userlog"
          render={(props: any) => (
            loggedIn ? (
              <UserLog
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/userviewdetails/:id?"
          render={(props: any) => (
            loggedIn ? (
              <UserViewDetails
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/userviewboards"
          render={(props: any) => (
            loggedIn ? (
              <UserViewBoards
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />

        <Route path="/tabs/useraccess"
          render={(props: any) => (
            loggedIn ? (
              <UserAccess
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />



        <Route path="/tabs/usercalendar"
          render={(props: any) => (
            loggedIn ? (
              <UserCalendar
                {...props}
              />
            ) : (
              <Login setUser={auth.setUser} />
            )
          )} />



        <Route
            path="/tabs/login"
            render={(props: any) => (
              <Login
                {...props}
                title="Login"
                setUser={auth.setUser}
              />
          )} />


        <Route path="/tabs/application"
          render={() => (
            <Application />
          )} />

        <Route
          path="/tabs/resetpwd/:email?"
          render={(props: any) => (
            <ResetPwd
              {...props}
              title="Reset Password"
            />
          )}
        />

        <Route
          path="/tabs/changepassword/:uid?"
          render={(props: any) => (
            <ChangePassword
              {...props}
              title="Change Password"
            />
          )}
        />


      </IonRouterOutlet>     
        


      


      <IonTabBar slot="bottom" style={{ height: loggedIn ? '0px':'60px'}}>
       
          <IonTabButton tab="application" href="/tabs/application"  >
            <IonIcon icon={homeOutline} />
            <IonLabel>
              Application</IonLabel>
          </IonTabButton>
          <IonTabButton tab="login" href="/tabs/login"  >
            <IonIcon icon={personOutline} />
            <IonLabel>
              Login</IonLabel>
          </IonTabButton>
         
      </IonTabBar>   

    </IonTabs>

  );
};

export default MainTabs;

function setUser(arg0: boolean) {
    throw new Error('Function not implemented.');
}
