import React, { useEffect, useReducer, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonButtons, IonCard, IonCardContent, IonSearchbar, useIonAlert } from '@ionic/react';
import { closeOutline, personAddOutline } from 'ionicons/icons';
import './Generic.css';
import { UpdateAppAccess } from '../data/api';

interface ModalAddProps {
  qSearchList: any,
  showWord:any,
  setShowWord: any,
  setShowModalAdd: any
}

const ModalAdd: React.FC<ModalAddProps> = ({ qSearchList, showWord, setShowWord, setShowModalAdd }) => {

  const [present] = useIonAlert();
  const [showTemp, setShowTemp] = useState("");

  function CheckKeyEvent(props: any) {
    if (props === 'Enter') {
      setShowWord(showTemp);
    }
  }

  return (<>
    <IonHeader >
      <IonToolbar color="tertiary">
        <IonTitle color="white">Grant Access to New User</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalAdd(false); }} color="transparent">

          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

      {qSearchList.isLoading && (
        <IonCard>
          <IonCardContent>
            <IonItem lines="none">
              Loading...
            </IonItem>
          </IonCardContent>
        </IonCard>
      )}

      <IonCard color="secondary"> 
        <IonCardContent>
          <p>Enter a word and hit [Enter Key] to search</p>
          <IonSearchbar showClearButton="always" onIonChange={(e) => setShowTemp(e.detail.value!)} onKeyUp={e => CheckKeyEvent(e.key!)} onIonClear={e => setShowTemp("")} />
          
        </IonCardContent>
      </IonCard>

      {qSearchList.isSuccess && qSearchList.data != null && qSearchList.data.map((dataString: any) =>
      (
        <>
          <IonItem href="javascript:void(0);" key={dataString.userid}
            onClick={() => {

              var title = "Which right do you want to assign to [" + dataString.username + "]?";
              present({
                header: "Attention",
                message: title,
                buttons: [
                  "Cancel",
                  {
                    text: "ReadOnly", handler: async () => {
                      const response = {
                        userid: dataString.userid,
                        userrights: "R"
                      }
                      const returnValue = await UpdateAppAccess(response);
                      setShowModalAdd(false);
                    }
                  },
                  {
                    text: "Administrator", handler: async () => {
                      const response = {
                        userid: dataString.userid,
                        userrights: "A"
                      }
                      const returnValue = await UpdateAppAccess(response);
                      setShowModalAdd(false);
                    }
                  }
                ]
              })






            }}
            >
            <IonIcon slot="start" icon={personAddOutline} size="large" color="primary" />
            <IonLabel>
              {dataString.username}
            </IonLabel>
            <IonLabel>
              {dataString.usertitle}
            </IonLabel>
          </IonItem>
        </>
      ))
      }
    </IonContent>
  </>);
}




export default ModalAdd;

