import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader,  IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem,  IonLabel, IonList, IonMenuButton,  IonPage, IonRow, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { alertCircleOutline, checkboxOutline,filterCircle, pencilOutline, personCircle, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppMemberList, GetAppUserRights } from '../data/api';
import { GetDailyCode } from '../data/utilities';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserMember: React.FC<GenericProps> = ({ match, title }) => {

  //const { user, setUser } = useAuth();
  //const code = GetDailyCode(null);

  const [showStatus, setShowStatus] = useState("");
  const [showPosition, setShowPosition] = useState<string|null>(null);
  const [showBoard, setShowBoard] = useState("0");
  const [showKeyword, setShowKeyword] = useState("");
  const [showTemp, setShowTemp] = useState("");

  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights());
  const queryList = useQuery("qMemberList", () => GetAppMemberList(showStatus, showPosition, showBoard, showKeyword));

  useEffect(() => {
    queryList.refetch();
  }, [showStatus, showPosition, showBoard, showKeyword]);

  //function CheckKeyEvent(props: any) {
  //  if (props === 'Enter') {
  //    setShowKeyword(showTemp);
  //    queryList.refetch();
  //  }
  //}

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Search Members of Boards, Commissions & Committees</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {queryList.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

        {queryList.isSuccess && (
          <>
            <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
              <IonList>
                <IonCard color="lightblue" id="-1">
                  <IonCardHeader>
                    <IonCardTitle><IonIcon icon={filterCircle} size="large" color="white" /> Filter{showStatus}</IonCardTitle>
                    <hr style={{ width: "100%", borderBottom: "1px solid white" }} />
                  </IonCardHeader>

                  <IonCardContent>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12">
                          <IonItem color="transparent" lines="none">
                            <IonSearchbar showClearButton="always"
                              onIonChange={(e) => setShowKeyword(e.detail.value!)}                
                              onIonClear={e => { setShowKeyword("");  }}
                              placeholder="Enter a name and then hit [Enter] to start searching..."
                            />                          
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem color="transparent" lines="none">
                            <IonLabel>Status</IonLabel>
                            <div className="select">
                              <select id="fStatus"
                                onChange={async (e) => {
                                  setShowStatus(e.target.value!);
                                  await new Promise(resolve => setTimeout(resolve, 100));
                                  //queryList.refetch();
                                }
                                }>
                                <option value="" >All</option>
                                <option value="A">Active (Serving)</option>
                                <option value="H">Inactive</option>
                                <option value="P">Pending</option>
                                <option value="R">Recommended</option>
                              </select>
                              <span className="focus"></span>
                            </div>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem color="transparent" lines="none">
                            <IonLabel>Serving Role</IonLabel>
                            <div className="select">
                              <select id="fPosition"
                                onChange={async (e) => {
                                  setShowPosition(e.target.value);
                                  await new Promise(resolve => setTimeout(resolve, 100));
                                  //queryList.refetch();

                                }
                                }>
                                <option value="null" >All</option>
                                <option value="Applicant">Applicant</option>
                                <option value="Chairman">Chairman</option>
                                <option value="Clerical">Clerical</option>
                                <option value="Council Member">Council Member</option>
                                <option value="Member">Member</option>    
                                <option value="Representative">Representative</option>
                                <option value="Staff">Staff</option>
                              </select>
                              <span className="focus"></span>
                            </div>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem color="transparent" lines="none">
                            <IonLabel>Boards/Commission</IonLabel>
                            <div className="select">
                              <select id="fBoards"
                                onChange={async (e) => {
                                  setShowBoard(e.target.value);
                                  await new Promise(resolve => setTimeout(resolve, 100));
                                  //queryList.refetch();
                                }
                                }>
                                <option value="0" >All</option>
                                <option value="1">Arts Council</option>
                                <option value="2">Beautification Commission</option>
                                <option value="3">Board of Adjustments</option>
                                <option value="4">Board of Building and Fire Code Appeals</option>
                                <option value="5">CARE Advisory Commission</option>
                                <option value="6">CDBG</option>
                                <option value="7">Heritage Advisory Commission</option>
                                <option value="23">Historic Preservation Advisory Commission</option>
                                <option value="8">Library Advisory Commission</option>
                                <option value="9">Metropolitan Water District of Orem</option>
                                <option value="10">Natural Resources Stewardship Committee</option>
                                <option value="21">Orem Neighborhood Commission</option>
                                <option value="11">Planning Commission</option>
                                <option value="18">Public Safety Advisory Commission</option>
                                <option value="13">Public Works Advisory Commission</option>
                                <option value="14">Recreation Advisory Commission</option>
                                <option value="16">Senior Citizen Advisory Commission</option>
                                <option value="15">OremFest Advisory Commission</option>
                                <option value="17">Transportation Advisory Commission</option>
                              </select>
                              <span className="focus"></span>
                            </div>
                          </IonItem>
                        </IonCol>

                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>

                {queryList.isSuccess && queryList.data && queryList.data.length > 0 && queryList.data.map((dataString: any) =>
                (
                  <>
                    <IonCard id={dataString.bcmemberid} href="javascript:void(0);">
                      <IonCardHeader color="lightblue">
                        <IonIcon icon={personCircle} size="large"
                          color={dataString.bcposition.trim() === "Member" ? "success" :
                            dataString.bcposition.trim() === "Chairman" ? "danger" :
                              dataString.bcposition.trim() === "Clerical" ? "warning" :
                                dataString.bcposition.trim() === "Representative" ? "secondary" :
                                dataString.bcposition.trim() === "Staff" ? "tertiary" : "white"} />
                        <IonItem color="transparent" lines="none"
                          onClick={
                            () => {
                              window.location.href = '/tabs/userviewdetails/' + dataString.bcmemberid;
                            }
                          }
                        >
                          <IonCardTitle>{dataString.bcname} </IonCardTitle>
                          <IonBadge color="primary" slot="end"><IonIcon icon={pencilOutline} color="white" size="large" /></IonBadge>    
                        </IonItem>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonItem>
                          <IonLabel>Serving Boards/Committee</IonLabel>
                          <IonLabel style={{ textAlign: 'right' }}>{dataString.bcbrdcomnm}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel>Position </IonLabel>
                          <IonLabel style={{ textAlign: 'right' }}>{dataString.bcposition}</IonLabel>
                        </IonItem>

                        {(dataString.bcposition.trim() === "Chairman" || dataString.bcposition.trim() === "Member" || dataString.bcposition.trim() === "Council Member") &&
                          (
                            <>
                              <IonItem>
                                <IonLabel>Term Length </IonLabel>
                                <IonLabel style={{ textAlign: 'right' }}>{dataString.bctermlen === 0 ? "" : dataString.bctermlen}</IonLabel>
                            </IonItem>

                            {dataString.bcapointed != null &&
                              (<>
                              <IonItem>
                                <IonLabel>Appointed Date</IonLabel>
                                <IonLabel style={{ textAlign: 'right' }}>{dataString.bcapointed}</IonLabel>

                                {new Date(dataString.bcapointed) > new Date() ?
                                  (
                                    <>
                                      &nbsp;<IonIcon icon={alertCircleOutline} color="danger" />
                                    </>
                                  )
                                  :
                                  (<>
                                    &nbsp;<IonIcon icon={checkboxOutline} color="success" />
                                  </>)
                                }


                              </IonItem>
                              <IonItem>
                                <IonLabel>Service End</IonLabel>
                                <IonLabel style={{ textAlign: 'right' }}>{dataString.bcenddat}</IonLabel>
                                {new Date(dataString.bcenddat) < new Date() ?
                                  (
                                    <>
                                      &nbsp;<IonIcon icon={alertCircleOutline} color="danger" />
                                    </>
                                  )
                                  :
                                  (<>
                                    &nbsp;<IonIcon icon={checkboxOutline} color="success" />
                                  </>)
                                }
                              </IonItem>
                              </>
                              )
                            }
                          

                            </>
                          )
                        }
                        <IonItem>
                          <IonLabel>Status </IonLabel>
                          <IonLabel style={{ textAlign: 'right' }}>
                            {dataString.bcstatus0 === "A" ? "Active" :
                              dataString.bcstatus0 === "H" ? "History" :
                                dataString.bcstatus0 === "P" ? "Pending" :
                                  dataString.bcstatus0 === "R" ? "Recomended" :
                                    "Uknown"
                                  }
                          </IonLabel>
                        </IonItem>
                      </IonCardContent>
                    </IonCard>

                  </>
                ))
                }
              </IonList>
            </div>
          </>
        )
        }
       
      </IonContent>


    </IonPage>
  )
}



export default UserMember;
