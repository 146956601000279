import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLoading, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { Formik } from 'formik';
import { bookOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import { RouteComponentProps } from 'react-router';
import { VerifyGoogleKey, VerifyAppEmail } from '../data/api';
import './Login.css';



interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ email: any }> { }
interface GenericProps extends RouteProps, StateProps { }

const ResetPwd: React.FC<GenericProps> = ({ match, title }) => {


  let useremail = match.params.email;

  const recaptchaRef = React.createRef<any>();
  const [present] = useIonAlert();
  const [showWaiting, setShowWaiting] = useState(false);
  ///const [showCaptcha, setShowCaptcha] = useState(false);



  function onChange(value: any) {
    //console.log('Captcha value:', value);
  }




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">Reset Password</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/login' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showWaiting}
          onDidDismiss={() => setShowWaiting(false)}
          message={'Please wait...'}
          duration={3000}
        />

        <Formik
          initialValues={{
            email: useremail
          }}



          onSubmit={async values => {

            const recaptchaValue = recaptchaRef.current.getValue();
            const result1 = await VerifyGoogleKey(recaptchaValue);

            if (result1 === "True") {
              const result2 = await VerifyAppEmail(values.email, true);

              if (result2 === "True") {

                present({
                  header: "Attention!",
                  message: "You should receive an email shortly. Please follow the instructions in this email to reset your password.",
                  buttons: [
                    {
                      text: "Ok", handler: async () => {       
                        window.location.href = "/tabs/login";
                      }
                    }
                  ]
                }) 
                
              }
              else {

                present("Error: the email does not exist in our database. Try again!")
                recaptchaRef.current.reset();
              }
            }
            else {
              if (result1 === "False") {

                present("Error: you failed the recaptcha bot check. Try again!");
                recaptchaRef.current.reset();
              }
            }

          }}
        >

          {(formikProps: any) => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ paddingBottom: "40px" }}
            >



              <IonCard>

                <IonCardHeader color="lightblue">
                  <IonText>
                    <IonIcon icon={bookOutline} size="large" />&nbsp;&nbsp;
                    Please enter your email to reset your password.
                  </IonText>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" className="login-colpadding">
                          <IonItem className="login-input">
                            <IonInput
                              type="text"
                              name="email"
                              placeholder="your@orem.gov"
                              onIonChange={formikProps.handleChange}
                              value={formikProps.values.email}
                              required={true}
                            />
                          </IonItem>
                        </IonCol>

                        <IonCol size="3">

                        </IonCol>
                        <IonCol size="7">
                          <IonItem lines="none">
                            <div className="App">
                              <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdRkyIqAAAAALHP1y72EhhdAXzq1Tq9zJkU7WbF"
                                onChange={onChange}
                              />
                            </div>
                          </IonItem>
                        </IonCol>
                        <IonCol size="2">
                        </IonCol>

                      </IonRow>
                    </IonGrid>

                  </IonItem>

                  <IonItem color="transparent" lines="none" >
                    <IonButton
                      size="large"
                      expand="block"
                      className="login-button"
                      type="submit"
                    >Submit</IonButton>
                  </IonItem>




                </IonCardContent>
              </IonCard>
            </form>
          )}
        </Formik>

      </IonContent>
    </IonPage>
  )
}

export default ResetPwd;
