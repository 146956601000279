import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonMenuButton, IonModal, IonPage, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { Formik } from 'formik';
import { ellipsisVerticalOutline, extensionPuzzleOutline, personCircleOutline, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Camera from '../components/Camera';
import { GetAppAvailabePositions, SubmitApplication, GetAppDocList, DeleteAppImage, GetAppBoardDesc } from '../data/api';
import { ApplicationSchema } from '../data/validations';
import { GetDailyCode } from '../data/utilities';
import ModalChoose from '../components/ModalChoose';
import './Application.css';

const Application: React.FC = (props) => {

  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useIonLoading();
  const [showRefresh, setRefresh] = useState(false);
  const [showAgree, setShowAgree] = useState(false);
  const [showModalChoose, setShowModalChoose] = useState(false);
  const [showBoardIds, setShowBoardIds] = useState<string | null>(null);
  const [showBoardIdsnm, setShowBoardIdsnm] = useState<string | null>(null);


  const [showUserEmail, setUserEmail] = useState<string | null>(null);

  const queryOpenPostions = useQuery("qOpenPostions", () => GetAppAvailabePositions(1));
  const queryBoards = useQuery("qBoards", () => GetAppBoardDesc(GetDailyCode(null), showBoardIds), {
    onSuccess: (data: any) => {
      if (data != null) { setShowBoardIdsnm(data); }
    }
  });
  const queryFiles = useMutation((data: any) => GetAppDocList(data));



  useEffect(() => {
    const response = {
      dailycode: GetDailyCode(showUserEmail),
      userid: showUserEmail
    }
    queryFiles.mutate(response);

  }, [showRefresh]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>Boards, Commissions & Committees Application</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light">




        <div style={{ padding: "5px" }}>

          <Formik
            initialValues={{
              vBcfirstnam: null,
              vBclastnam: null,
              vBcaddress1: null,
              vBcaddress2: "",
              vBccity: "Orem",
              vBcstate: 'UT',
              vBczipcode: null,
              vBcphonenum: null,
              vBctextmsg: null,
              vBcemail: null,
              vBcbrdcomids: null,
              vBcsummary: null
            }}


            validationSchema={ApplicationSchema}




            onSubmit={async values => {
              const response = {
                dailycode: GetDailyCode(values.vBcemail),
                Bcfirstnam: values.vBcfirstnam,
                Bclastnam: values.vBclastnam,
                Bcaddress1: values.vBcaddress1,
                Bcaddress2: String(values.vBcaddress2),
                Bccity: values.vBccity,
                Bcstate: values.vBcstate,
                Bczipcode: String(values.vBczipcode),
                Bcphonenum: String(values.vBcphonenum),
                Bctextmsg: values.vBctextmsg,
                Bcemail: values.vBcemail,
                Bcbrdcomids: String(values.vBcbrdcomids),
                Bcsummary: values.vBcsummary
              }


              const returnValue = await SubmitApplication(response);
              if (returnValue == "success") {

                present({
                  header: "Attention!",
                  message: "Thank you for applying for the position and supporting our community. Your application has been received!",
                  buttons: [
                    {
                      text: "Ok", handler: async () => {
                        window.location.href = "/tabs/application/";
                      }
                    }
                  ]
                })



              }
              else {
                present("Sorry that we are unable to create your profile due to the following error:" + returnValue);
              }



            }}
          >
            {(formikProps: any) => (
              <form
                onSubmit={formikProps.handleSubmit}
                style={{ paddingBottom: "40px" }}
              >


                <IonCard color="lightyellow">
                  <IonCardHeader >
                    <IonCardTitle>Agreement</IonCardTitle>
                    <hr style={{ width: "100%", borderBottom: "1px solid black" }} />
                  </IonCardHeader>
                  <IonCardContent>

                    Welcome to our new online boards-commission-committee app portal. If you experience any difficulties, please email bcc@orem.gov.
                    <p style={{ height: "5px" }}></p>
                    To help safeguard the public, the City withholds the right to perform a background check on all volunteers.
                    <p style={{ height: "5px" }}></p>
                    As a condition of volunteering, I give the City of Orem permission to conduct a thorough background check on me,
                    which may include a review of city records, sex offender registries, criminal history records, driving records
                    and federal FBI records. I understand that all volunteer positions are conditioned upon the City never receiving
                    inappropriate information on my background.
                    <p style={{ height: "5px" }}></p>
                    As a volunteer, I agree to be subject to the policies and procedures of the City of Orem and I will abide
                    by the Volunteer Code of Conduct and will treat everyone with respect, loyalty, patience, integrity, courtesy,
                    dignity and consideration.
                    <p style={{ height: "5px" }}></p>
                    By submitting this application, I affirm that the facts set forth in it are true and complete. I understand that
                    if I am accepted as a volunteer, any false statements, omissions, or other misrepresentations made by me on this
                    application may result in my immediate dismissal.


                  </IonCardContent>
                </IonCard>
                <p style={{ height: "0px" }}></p>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle><IonIcon icon={personCircleOutline} size="large" /> &nbsp; Personal Information</IonCardTitle>
                    <hr style={{ width: "100%", borderBottom: "1px solid grey" }} />
                  </IonCardHeader>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    <IonCardContent>
                      {/*Last*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*Last name</IonLabel>
                        <IonInput
                          type="text"
                          name="vBclastnam"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="30"
                          autocomplete="family-name"
                          value={formikProps.values.vBclastnam}
                          required={true}
                          autofocus
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBclastnam && formikProps.errors.vBclastnam}
                      </IonLabel>
                      {/*First*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*First name</IonLabel>
                        <IonInput
                          type="text"
                          name="vBcfirstnam"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="30"
                          autocomplete="given-name"
                          value={formikProps.values.vBcfirstnam}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBcfirstnam && formikProps.errors.vBcfirstnam}
                      </IonLabel>
                      {/*Address*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*Address</IonLabel>
                        <IonItemGroup>
                          <IonInput
                            type="text"
                            name="vBcaddress1"
                            onIonChange={formikProps.handleChange}
                            min="1"
                            max="50"
                            autocomplete="on"
                            value={formikProps.values.vBcaddress1}
                            required={false}
                          />
                          <IonInput
                            type="text"
                            name="vBcaddress2"
                            onIonChange={formikProps.handleChange}
                            min="1"
                            max="50"
                            placeholder="Address 2 (Optional)"
                            autocomplete="on"
                            value={formikProps.values.vBcaddress2}
                            required={false}
                          />
                        </IonItemGroup>
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBcaddress1 && formikProps.errors.vBcaddress1}
                      </IonLabel>
                      {/*City*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*City (Prerequiste)</IonLabel>
                        <IonInput
                          readonly={true}
                          type="text"
                          name="vBccity"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="100"
                          autocomplete="on"
                          value={formikProps.values.vBccity}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBccity && formikProps.errors.vBccity}
                      </IonLabel>
                      {/*State*/}
                      <IonItem fill="outline" mode="md" lines="none">
                        <IonLabel position="fixed">*State</IonLabel>
                        <div className="select" slot="end">
                          <select id="HASTATE"
                            onChange={(e) => {
                              formikProps.values.vBcstate = e.target.value;
                              formikProps.handleChange(e);
                            }}
                            value={formikProps.values.vBcstate}>
                            <option value='AL'>Alabama</option>
                            <option value='AK'>Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                          <span className="focus"></span>
                        </div>
                      </IonItem>
                      <p style={{ height: "18px" }}></p>
                      {/*Zip*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*Zip Code</IonLabel>
                        <IonInput
                          type="text"
                          name="vBczipcode"
                          onIonChange={formikProps.handleChange}
                          maxlength={5}
                          autocomplete="postal-code"
                          value={formikProps.values.vBczipcode}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBczipcode && formikProps.errors.vBczipcode}
                      </IonLabel>
                      {/*Phone*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*Phone #</IonLabel>
                        <IonInput
                          name="vBcphonenum"
                          type="text"
                          maxlength={10}
                          value={formikProps.values.vBcphonenum}
                          onIonChange={formikProps.handleChange}
                          autocomplete="off"
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBcphonenum && formikProps.errors.vBcphonenum}
                      </IonLabel>
                      {/*Phone Text Choice*/}
                      <IonItem fill="outline" mode="md" lines="none">
                        <IonLabel position="fixed">*Accept texting?</IonLabel>
                        <div className="select" slot="end">
                          <select id="vBctextmsg"
                            onChange={(e) => {
                              formikProps.values.vBctextmsg = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.vBctextmsg}  >

                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBctextmsg && formikProps.errors.vBctextmsg}
                      </IonLabel>
                      <p style={{ height: "18px" }}></p>
                      {/*Email*/}
                      <IonItem fill="outline" mode="md">
                        <IonLabel position="floating">*Your Email</IonLabel>
                        <IonInput
                          type="text"
                          name="vBcemail"
                          onIonChange={(e) => {
                            formikProps.values.vBcemail = e.detail.value;
                            if (e.detail.value != null) {
                              setUserEmail(e.detail.value);
                            }
                            formikProps.handleChange(e);
                          }}
                          min="1"
                          max="30"
                          autocomplete="family-name"
                          value={formikProps.values.vBcemail}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBcemail && formikProps.errors.vBcemail}
                      </IonLabel>

                    </IonCardContent>
                  </div>
                </IonCard>
                <p style={{ height: "0px" }}></p>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle><IonIcon icon={extensionPuzzleOutline} size="large" /> &nbsp; Boards/Commissions Positions</IonCardTitle>
                    <hr style={{ width: "100%", borderBottom: "1px solid grey" }} />
                  </IonCardHeader>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>

                    <IonCardContent>

                      {/*Apply For*/}
                      <IonItem fill="outline" mode="md" lines="none">
                        <IonLabel position="fixed">*Apply For</IonLabel>
                        <IonText>
                          {showBoardIdsnm}
                        </IonText>
                        <IonButtons slot="end" onClick={() => setShowModalChoose(true)}  >
                          <IonButton >
                            <IonIcon icon={ellipsisVerticalOutline} color="black" />
                          </IonButton>
                        </IonButtons>
                      </IonItem>



                      {/*<IonItem>*/}
                      {/*  <IonLabel style={{ color: "#808080" }} >*Select ones which you would like to apply</IonLabel>*/}

                      {/*  <IonSelect*/}
                      {/*    interface="popover"*/}
                      {/*    mode="ios"*/}
                      {/*    multiple*/}
                      {/*    value={formikProps.values.vBcbrdcomids}*/}
                      {/*    onIonChange={(e) => {*/}

                      {/*      formikProps.values.vBcbrdcomids = e.detail.value;    */}
                      {/*      formikProps.handleChange(e);*/}

                      {/*    }}*/}
                      {/*  >*/}
                      {/*    {queryOpenPostions && queryOpenPostions.data && queryOpenPostions.data.length > 0 && queryOpenPostions.data.map((dataString: any) =>*/}
                      {/*    (*/}
                      {/*      <IonSelectOption value={dataString.bcindex}>{dataString.bcname}</IonSelectOption>*/}
                      {/*      )*/}
                      {/*      )}                  */}
                      {/*  </IonSelect>  */}
                      {/*</IonItem>*/}
                      {/*<IonLabel color="danger" className="ion-padding">*/}
                      {/*  {formikProps.touched.vBcbrdcomids && formikProps.errors.vBcbrdcomids}*/}
                      {/*</IonLabel>*/}

                      {/*Write Summary*/}
                      <p style={{ height: "10px" }}></p>

                      {/*<IonItem fill="outline" mode="md" lines="none" style={{ borderStyle: 'none !important' }}>*/}
                      {/*  <IonText >*Summarize why you wish to serve on a city commission and if*/}
                      {/*    there are areas of concern that you would like to see addressed by a city commission.</IonText>*/}
                      {/*</IonItem>*/}

                      <IonItem fill="outline" mode="md">
                        <IonTextarea
                          rows={10}
                          minlength={1}
                          maxlength={1000}
                          name="vBcsummary"
                          placeholder="*Summarize why you wish to serve on a city commission and if
                          there are areas of concern that you would like to see addressed by a city commission."
                          value={formikProps.values.vBcsummary}
                          onIonChange={formikProps.handleChange}></IonTextarea>
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.vBcsummary && formikProps.errors.vBcsummary}
                      </IonLabel>

                    </IonCardContent>


                  </div>


                </IonCard>

                {showUserEmail != null && showUserEmail !== "" && (
                  <>
                    <p style={{ height: "0px" }}></p>
                    <IonCard>
                      <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                        <IonCardContent>
                          <Camera useremail={showUserEmail} setRefresh={setRefresh} />
                        </IonCardContent>
                      </div>
                    </IonCard>
                    {queryFiles.isSuccess && queryFiles.data != null && queryFiles.data.map((dataString: any) =>
                    (
                      <>
                        <IonCard>
                          <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                            <IonCardContent>
                              <IonItem key={dataString.bcimgidx}>
                                <IonButtons slot="start"
                                  onClick={() => {
                                    present({
                                      header: "Attention!",
                                      message: "Are you sure you want to delete this file?",
                                      buttons: [
                                        "Cancel",
                                        {
                                          text: "Yes", handler: async () => {
                                            showLoading({ message: "Deleting file...", duration: 3000 });
                                            const response = {
                                              dailycode: GetDailyCode(showUserEmail),
                                              email: showUserEmail,
                                              index: dataString.bcimgidx
                                            }

                                            const returnValue = await DeleteAppImage(response);
                                            if (returnValue === "success") {
                                              setRefresh(true);
                                              present("The file is deleted!");
                                              setRefresh(false);
                                            }
                                            else {
                                              present("Sorry that we are unable to delete the file due to the following error:" + returnValue);
                                            }

                                          }
                                        }
                                      ]
                                    })
                                  }}

                                >
                                  <IonIcon icon={trashOutline} size="large" color="primary" />
                                </IonButtons>
                                <IonLabel>{dataString.filename}</IonLabel >
                                <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                              </IonItem>
                            </IonCardContent>
                          </div>
                        </IonCard>
                      </>
                    ))
                    }
                  </>)}


                <p style={{ height: "0px" }}></p>
                <IonCard >
                  <IonCardHeader style={{ textAlign: 'center' }}>
                    <IonCardTitle>Acknowledgement</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <hr style={{ width: "100%", borderBottom: "1px solid grey" }} />
                    It is the policy of this organization to provide equal opportunities without regard to race, color, religion,
                    national origin, gender, sexual preference, age, or disability.
                    <p style={{ height: "5px" }}></p>
                    This application does not guarantee an appointment to one of the city’s citizen commissions.
                    <p style={{ height: "5px" }}></p>
                    The selection process may include consideration by the city elected body, narrowed down over two (2) council
                    meetings or may require a recommendation from the City Manager. If you are not selected at this time, your
                    application will remain on file for two (2) year period, to be considered again should a new vacancy occur.
                    <p style={{ height: "5px" }}></p>
                    Thank you for completing this application form and for your interest in serving the City of Orem!
                    <p style={{ height: "10px" }}></p>
                    <IonItem lines="none" color="light" >
                      <IonCheckbox mode="ios" color="danger" onIonChange={e => setShowAgree(e.detail.checked)} />
                      <IonLabel>I have read the acknowledgement and I agree with it</IonLabel> {showAgree}
                    </IonItem>
                  </IonCardContent>
                </IonCard>


                {/*Submit Button*/}
                {showAgree && (
                  <div style={{ paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                    <IonButton
                      color="success"
                      expand="block"
                      type="submit"
                      size="large"
                    >Submit</IonButton>

                  </div>
                )}


                <IonModal isOpen={showModalChoose}
                  backdropDismiss={false}
                  onDidDismiss={() => {
                    formikProps.values.vBcbrdcomids = showBoardIds;
                    setShowModalChoose(false); queryBoards.refetch();
                  }}>
                  <ModalChoose
                    qBoardList={queryOpenPostions}
                    bMultiple={true}
                    setShowBoardIds={setShowBoardIds}
                    setShowModalChoose={setShowModalChoose} >
                  </ModalChoose>
                </IonModal>

              </form>
            )}





          </Formik>

        </div>
      </IonContent>
    </IonPage>
  )
}



export default Application;
