import React, { useEffect, useReducer, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonInput, IonButtons, IonCard, IonCardContent, IonSearchbar, useIonAlert, IonList, IonCheckbox, IonRadio } from '@ionic/react';
import { Formik } from 'formik';
import { closeOutline, personAddOutline, push } from 'ionicons/icons';
import './Generic.css';
import { UpdateAppAccess } from '../data/api';

interface ModalAddProps {
  qBoardList: any,
  bMultiple: boolean,
  setShowBoardIds: any,
  setShowModalChoose: any
}

const ModalChoose: React.FC<ModalAddProps> = ({ qBoardList, bMultiple, setShowBoardIds,  setShowModalChoose }) => {

  const [present] = useIonAlert();
  // let selectedids = String[];
  let selectedids: string[] = [];

  function RemoveId(item:string) {
    var index = selectedids.indexOf(item);
    if (index !== -1) {
      selectedids.splice(index, 1);
    }
  }

  function AddId(item:string) {
    var index = selectedids.indexOf(item);
    if (index === -1) {
      selectedids.push(item);
    }
  }


  return (<>
    <IonHeader >
      <IonToolbar color="tertiary">
        <IonTitle color="white">Select Boards, Commissions & Committees</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalChoose(false); }} color="white">

          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <IonList>
        {qBoardList && qBoardList.data && qBoardList.data.length > 0 && qBoardList.data.map((dataString: any) =>
        (
          <> 
          {bMultiple?
              (
                  <>
                    <IonItem>
                      <IonCheckbox slot="start"
                        mode="ios"
                        value={dataString.bcindex}
                        onIonChange={
                          (e) => {
                            if (e.detail.checked) 
                              AddId(e.detail.value);                         
                            else 
                              RemoveId(e.detail.value); 
                          }
                        }

                      ></IonCheckbox>
                      <IonLabel>{dataString.bcname}</IonLabel>
                    </IonItem>
              </>
              )
              :
              (
                <>
                  <IonItem href="javascript:void(0);"
                    onClick={() => {

                      setShowBoardIds(dataString.bcindex);
                      setShowModalChoose(false);
                    }
                    }
                    >
                    <IonRadio slot="start"></IonRadio>
                    <IonLabel>{dataString.bcname}</IonLabel>
                  </IonItem>
                 </>
              )
          }            
          </>
        ))
        }
      </IonList>
      {bMultiple &&
      (<>
      <div style={{ paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
        <IonButton
          style={{ textTransform:'none!important'}}
          color="success"
          expand="block"
          size="large"
          onClick={
            () => {
              // console.log(selectedids);
              setShowBoardIds(selectedids.toString());
              setShowModalChoose(false);
            }
          }
        >Submit</IonButton>
      </div>
      </>)
      }

    </IonContent>
  </>);
}

export default ModalChoose;

