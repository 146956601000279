import React, { createContext, useContext} from 'react';

export type GlobalContent = {
  order: string
  setOrder: (c: string) => void
  searchText: string
  setSearchText: (c: string) => void
  flagIn: string;
  setFlagIn: (c: string) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
  order: '', // set a default value
  setOrder: () => { },
  searchText: '', // set a default value
  setSearchText: () => { },
  flagIn: '', // set a default value
  setFlagIn: () => { }
})

export const useGlobalContext = () => useContext(MyGlobalContext)
