import React from 'react';
import { useLocation } from 'react-router';
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { moonOutline, homeOutline,  exitOutline, busOutline, buildOutline, flagOutline, personOutline, listOutline } from 'ionicons/icons';
import './Menu.css'
import useAuth from '../data/useAuth';
import { LogoutApp, GetAppOperationHours } from '../data/api';
import { useQuery } from 'react-query';


const routes = {
  appPages: [
    {  },  
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: any
}

interface MenuProps extends StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {

  const location = useLocation();
  const { user, setUser } = useAuth();
  const loggedIn: boolean = user ? true : false;

  const hoursQuery = useQuery("Hours", () => GetAppOperationHours());

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined} >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  function ExistApp() {
    setUser(false);
    LogoutApp();
  }

  return (

    <IonMenu  type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonItem>
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
              <IonImg src="/assets/icon/app0.png" style={{ height:'260px', filter: darkMode ? "invert(1) hue-rotate(180deg)" : "" }} />
            </div>
          </IonItem>
        </IonList>

        
          {/*{renderlistItems(routes.appPages)}*/}

          {/*Not Login Yet ===========================================================================*/}
          {!loggedIn && (
            <>
              <IonList lines="none">
              <IonItem href="/tabs/application">
                <IonIcon slot="start" icon={homeOutline} />
                <IonLabel>Application</IonLabel>
              </IonItem>

              <IonItem href="/tabs/login">
                <IonIcon slot="start" icon={personOutline} />
                <IonLabel>Staff Login</IonLabel>
              </IonItem>
              </IonList>
              
            <IonList lines="none">
                <IonListHeader>Settings</IonListHeader>
                <IonItem>
                  <IonIcon slot="start" icon={moonOutline}></IonIcon>
                  <IonLabel>Dark Mode</IonLabel>
                  <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
                </IonItem>
              </IonList>
            </>
          )}
        

        {/*Logined Yet ===========================================================================*/}
        {loggedIn && (
          <>
            <IonList lines="none">
              <IonListHeader>STAFF ONLY</IonListHeader>
              <IonItem href="/tabs/usermenu" >
                <IonIcon slot="start" icon={listOutline} />
                <IonLabel>Main Menu</IonLabel>
              </IonItem>
              <IonItem href="/" onClick={() => { ExistApp(); }}>
                <IonIcon slot="start" icon={exitOutline} />
                <IonLabel>Log Out</IonLabel>
              </IonItem>
            </IonList>
           
          </>
        )}

        

        {hoursQuery.isSuccess && hoursQuery.data != null && (
          <>
            <IonList>
              <IonListHeader>Contact Info</IonListHeader>
              <div className="ion-text-wrap, ion-text-center">
                <IonLabel style={{ fontSize: '12px' }}>56 N State Street, Orem, UT 84057</IonLabel>
                <br></br>
                <IonLabel style={{ fontSize: '12px' }}>(801) 229-7298</IonLabel>
                <br></br>

                <IonLabel style={{ fontSize: '12px' }}><strong>Hours</strong>:&nbsp;
                  {hoursQuery.data.status1 === "Open" &&
                    (
                      <>
                        <strong style={{ color: 'green' }}>{hoursQuery.data.status1} </strong>
                      </>
                    )
                  }
                  {hoursQuery.data.status1 === "Closed" &&
                    (
                      <>
                        <strong style={{ color: 'red' }}>{hoursQuery.data.status1} </strong>
                      </>
                    )
                  }
                  - {hoursQuery.data.status2} {hoursQuery.data.hours}</IonLabel>
              </div>
            </IonList>
          </>
        )}


      </IonContent>
    </IonMenu>

  );
};

export default Menu;
