import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem,  IonLabel, IonList, IonMenuButton,  IonModal,  IonPage, IonRouterLink, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { documentAttachOutline, idCardOutline, mailOutline, manOutline, peopleOutline, personOutline, returnDownBackOutline, schoolOutline, starOutline, womanOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppMemberList, GetAppUserRights, GetAppSetting } from '../data/api';
import useAuth from '../data/useAuth';
import ModalEmail from '../components/ModalEmail';
import './Generic.css';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserViewBoards: React.FC<GenericProps> = ({ match, title }) => {

  const { user, setUser } = useAuth();
  const [showBoard, setShowBoard] = useState("0");
  const [showDesc, setDesc] = useState(null);
  const [showMemberSize, setMemberSize] = useState(0);
  const [showSrvMembers, setShowSrvMembers] = useState(0);
  const [showTermLen, setTermLen] = useState(0);
  const [showAppointBy, setAppointBy] = useState(null);
  const [showModalEmail, setShowModalEmail] = useState(false);


  // const queryUserRights = useQuery("qUserRights", () => GetAppUserRights());

  const queryUserRights = useQuery("qUserRights", () => GetAppUserRights(), {
    onSuccess: (data: any) => {
      if (data != 'A' && data != 'R') {
        setUser(null);
        window.location.href = '/tabs/login';
      }
    }
  });


  const queryMMList = useQuery("qMember", () => GetAppMemberList("A", "Member", showBoard, ""), { enabled: false });
  const queryCMList = useQuery("qCouncil", () => GetAppMemberList("A", "Council Member", showBoard, ""), { enabled: false });
  const queryCHList = useQuery("qChairman", () => GetAppMemberList("A", "Chairman", showBoard, ""), { enabled: false });
  const querySFList = useQuery("qStaff", () => GetAppMemberList("A", "Staff", showBoard, ""), { enabled: false });
  const queryCKList = useQuery("qSecretary", () => GetAppMemberList("A", "Clerical", showBoard, ""), { enabled: false });
  const queryRPList = useQuery("qReresentative", () => GetAppMemberList("A", "Representative", showBoard, ""), { enabled: false });
  const queryRKList = useQuery("qRecomend", () => GetAppMemberList("R", "Applicant", showBoard, ""), { enabled: false });
  const queryAPList = useQuery("qApplicant", () => GetAppMemberList("P", "Applicant", showBoard, ""), { enabled: false });


  const querySetting = useQuery("qSetting", () => GetAppSetting(showBoard), {
    onSuccess: (data: any) => {
      data.map((dataString: any) => {
        setDesc(dataString.bcname);
        setMemberSize(dataString.bcmembers);
        setShowSrvMembers(dataString.servingMembers);
        setTermLen(dataString.bctermlen);
        setAppointBy(dataString.bcaptby);
      })
    }
  });


  //useEffect(() => {
  //  queryMMList.refetch().then(
  //    () => queryCMList.refetch().then(
  //      () => queryCHList.refetch().then(
  //        () => querySFList.refetch().then(
  //          () => queryCKList.refetch().then(
  //            () => queryCKList.refetch().then(
  //              () => queryRKList.refetch().then(
  //                () => queryAPList.refetch().then(
  //                  () => querySetting.refetch()
  //                )
  //              )
  //            )
  //          )
  //        )
  //      )
  //    )
  //  );    
  //}, [showBoard]);




    return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>View Boards Members</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {queryMMList.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}

       
        
        <IonCard color="lightblue">
          <IonCardHeader>
            <IonCardTitle>Select the Boards, Commission or Committee</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <hr style={{ width: "100%", borderBottom: "1px solid white" }} />
            <IonItem color="transparent" lines="none">
              <IonLabel>Boards/Commission</IonLabel>
              <div className="select">
                <select id="fBoards"
                  onChange={ async (e) => {
                    setShowBoard(e.target.value!);
                    if (showBoard != null) {
                      new Promise(resolve => setTimeout(resolve, 1000));
                      await querySetting.refetch();
                      await querySetting.refetch();
                      await queryMMList.refetch();
                      await queryCMList.refetch();
                      await queryCHList.refetch();
                      await querySFList.refetch();
                      await queryCKList.refetch();
                      await queryRPList.refetch();
                      await queryRKList.refetch();
                      await queryAPList.refetch();  
                      
                      new Promise(resolve => setTimeout(resolve, 500));
                    }
                    
                  }
                  }>
                  <option value="0" >Select One</option>
                  <option value="1">Arts Council</option>
                  <option value="2">Beautification Commission</option>
                  <option value="3">Board of Adjustments</option>
                  <option value="4">Board of Building and Fire Code Appeals</option>
                  <option value="5">CARE Advisory Commission</option>
                  <option value="6">CDBG</option>
                  <option value="7">Heritage Advisory Commission</option>
                  <option value="23">Historic Preservation Advisory Commission</option>
                  <option value="8">Library Advisory Commission</option>
                  <option value="9">Metropolitan Water District of Orem</option>
                  <option value="10">Natural Resources Stewardship Committee</option>
                  <option value="21">Orem Neighborhood Commission</option>
                  <option value="11">Planning Commission</option>
                  <option value="18">Public Safety Advisory Commission</option>
                  <option value="13">Public Works Advisory Commission</option>
                  <option value="14">Recreation Advisory Commission</option>
                  <option value="16">Senior Citizen Advisory Commission</option>
                  <option value="15">OremFest Advisory Commission</option>
                  <option value="17">Transportation Advisory Commission</option>
                </select>
                <span className="focus"></span>
              </div>
            </IonItem>
          </IonCardContent>
        </IonCard>
        <p style={{ height: "0px" }}></p>
        

        {showBoard !== "0" &&
          (
          <IonCard>
            <IonCardHeader color="lightblue">
              <IonItem color="transparent" lines="none">
                <IonCardTitle slot="start">{showDesc}</IonCardTitle>
                <IonBadge slot="end" onClick={() => setShowModalEmail(true)} >
                  <IonIcon icon={mailOutline} size="large"  />
                </IonBadge>
                </IonItem>
            </IonCardHeader>
              <IonCardContent>
              <IonList>
                <IonGrid>
                  <IonRow style={{ backgroundColor: '#fffcf4', textAlign: 'center' }}>
                    <IonCol className="cellstyle" size="3" >Max #</IonCol>
                    <IonCol className="cellstyle" size="3" >Active #</IonCol>
                    <IonCol className="cellstyle" size="3" >Term Length</IonCol>
                    <IonCol className="cellstyle" size="3" >Appointed By</IonCol>   
                  </IonRow>
                  <IonRow style={{ textAlign: 'center' }}>
                    <IonCol className="cellstyle" size="3" >{showMemberSize}</IonCol>
                    <IonCol className="cellstyle" size="3" >{showSrvMembers}</IonCol>
                    <IonCol className="cellstyle" size="3" >{showTermLen}</IonCol>
                    <IonCol className="cellstyle" size="3" >{showAppointBy}</IonCol>
                  </IonRow>
                </IonGrid>

                  {/*City Council*/}
                  {queryCMList.isSuccess && queryCMList.data && queryCMList.data.length > 0 &&
                    (
                      <IonGrid>
                        <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                        <IonCol className="cellstyle" > <IonIcon icon={schoolOutline} /> &nbsp; <strong>City Council Member(s)</strong></IonCol>
                        </IonRow>
                        <IonRow >
                          <IonCol className="cellstyle" size="4" ><strong>Name</strong></IonCol>
                          <IonCol className="cellstyle" size="4"><strong>Email</strong></IonCol>
                          <IonCol className="cellstyle" size="2"><strong>Phone</strong></IonCol>
                          <IonCol className="cellstyle" size="2" ><strong>*End/Created</strong></IonCol>
                        </IonRow>
                        {queryCMList.isSuccess && queryCMList.data && queryCMList.data.length > 0 && queryCMList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2"  style={{ textAlign: 'right' }}>N/A</IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }


                  {/*Chairman*/}
                  {queryCHList.isSuccess && queryCHList.data && queryCHList.data.length > 0 &&
                    (
                      <IonGrid>
                    <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                      <IonCol className="cellstyle" > <IonIcon icon={manOutline} /> &nbsp; <strong>Chairmen / Vice Chairmen</strong></IonCol>
                        </IonRow>

                    {queryCHList.isSuccess && queryCHList.data && queryCHList.data.length > 0 && queryCHList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>
                                {dataString.bcenddat}
                              </IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }



                  {/*Members*/}
                  {queryMMList.isSuccess && queryMMList.data && queryMMList.data.length > 0 &&
                    (
                      <IonGrid>
                    <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                      <IonCol className="cellstyle" > <IonIcon icon={peopleOutline} /> &nbsp; <strong>Member(s)</strong></IonCol>
                        </IonRow>
                        {queryMMList.isSuccess && queryMMList.data && queryMMList.data.length > 0 && queryMMList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>
                                {dataString.bcenddat} 
                              </IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }

                {/*Representative*/}
                {queryRPList.isSuccess && queryRPList.data && queryRPList.data.length > 0 &&
                  (
                    <IonGrid>
                      <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                        <IonCol className="cellstyle" > <IonIcon icon={peopleOutline} /> &nbsp; <strong>Representative(s)</strong></IonCol>
                      </IonRow>
                    {queryRPList.isSuccess && queryMMList.data && queryRPList.data.length > 0 && queryRPList.data.map((dataString: any) =>
                        <>
                          <IonRow id={dataString.bcmemberid}>
                            <IonCol className="cellstyle" size="4" >
                              <IonRouterLink
                                color="tertiary"
                                href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                            </IonCol>
                            <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                            <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                            <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>
                              N/A
                            </IonCol>
                          </IonRow>
                        </>
                      )
                      }
                    </IonGrid>
                  )
                }


                  {/*City Staff*/}
                  {querySFList.isSuccess && querySFList.data && querySFList.data.length > 0 &&
                    (
                      <IonGrid>
                    <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                      <IonCol className="cellstyle" > <IonIcon icon={idCardOutline} /> &nbsp; <strong>City Staff Member(s)</strong></IonCol>
                        </IonRow>

                        {querySFList.isSuccess && querySFList.data && querySFList.data.length > 0 && querySFList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>N/A</IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }

                  {/*City Secretary*/}
                  {queryCKList.isSuccess && queryCKList.data && queryCKList.data.length > 0 &&
                    (
                      <IonGrid>
                    <IonRow style={{ backgroundColor: '#B7FFBF' }}>
                      <IonCol className="cellstyle" > <IonIcon icon={womanOutline} /> &nbsp; <strong>Clerical(s)</strong></IonCol>
                        </IonRow>
                        {queryCKList.isSuccess && queryCKList.data && queryCKList.data.length > 0 && queryCKList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>N/A</IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }


                  {/*Recommended*/}
                  {queryRKList.isSuccess && queryRKList.data && queryRKList.data.length > 0 &&
                    (
                      <IonGrid>
                        <IonRow style={{ backgroundColor: '#F699CD' }}>
                          <IonCol className="cellstyle" > <IonIcon icon={starOutline} /> &nbsp; <strong>Recommended Applicant(s)</strong></IonCol>
                        </IonRow>
                        {queryRKList.isSuccess && queryRKList.data && queryRKList.data.length > 0 && queryRKList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}</IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4">{dataString.bcemail}</IonCol>
                              <IonCol className="cellstyle" size="2">{dataString.bcphonenum}</IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}>N/A</IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }

                  {/*Applicants*/}
                  {queryAPList.isSuccess && queryAPList.data && queryAPList.data.length > 0 &&
                    (
                      <IonGrid>
                    <IonRow style={{ backgroundColor: '#E2E2E2' }}>
                      <IonCol className="cellstyle" > <IonIcon icon={personOutline} /> &nbsp; <strong>Applicant(s)</strong></IonCol>
                        </IonRow>
                        {queryAPList.isSuccess && queryAPList.data && queryAPList.data.length > 0 && queryAPList.data.map((dataString: any) =>
                          <>
                            <IonRow id={dataString.bcmemberid}>
                              <IonCol className="cellstyle" size="4" >
                                <IonRouterLink
                                  color="tertiary"
                                  href={'/tabs/userviewdetails/' + dataString.bcmemberid}  >{dataString.bcname}

                                {dataString.bcattachment === "Y" &&
                                  (
                                  <>
                                      &nbsp;  &nbsp; (<IonIcon icon={documentAttachOutline} color="tertiary" />)
                                  </>
                                  )
                                }
                                </IonRouterLink>
                              </IonCol>
                              <IonCol className="cellstyle" size="4"><span style={{ color: '#E2E2E2' }}>
                                {dataString.bcemail}
                              </span></IonCol>
                                <IonCol className="cellstyle" size="2"><span style={{ color: '#E2E2E2' }}>{dataString.bcphonenum}</span></IonCol>
                              <IonCol className="cellstyle" size="2" style={{ textAlign: 'right' }}><span style={{color:'#E2E2E2'}}>{dataString.bcstamp}</span></IonCol>
                            </IonRow>
                          </>
                        )
                        }
                      </IonGrid>
                    )
                  }

                </IonList>
              </IonCardContent>
            </IonCard>
          )
        }

        <p style={{ height: "0px" }}></p>


        <IonModal isOpen={showModalEmail}
          backdropDismiss={false}
          onDidDismiss={() => {
            setShowModalEmail(false);
          }}>
          <ModalEmail
            showBoard={showBoard}
            showDesc={showDesc}
            setShowModalEmail={setShowModalEmail} >
          </ModalEmail>
        </IonModal>



      </IonContent>


    </IonPage>
  )
}



export default UserViewBoards;
