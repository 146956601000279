import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemGroup, IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { createOutline, filterCircle, personAddOutline, personCircle, personOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import ModalAdd from '../components/ModalAdd';
import { GetAppAccessList, GetAppUserList, UpdateAppAccess } from '../data/api';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{}> { }
interface GenericProps extends RouteProps, StateProps { }

const UserAccess: React.FC<GenericProps> = ({ match, title }) => {
  
  const [present] = useIonAlert();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showWord, setShowWord] = useState(null);
  const queryAccessList = useQuery("qUserList", () => GetAppAccessList());
  const qSearchList = useQuery("qSearchList", () => GetAppUserList(showWord));


  useEffect(() => {
    queryAccessList.refetch();
    qSearchList.refetch();
  }, [showWord]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary" style={{ height: '60px', paddingTop: '10px' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white" style={{ paddingBottom: '12px' }}>User List</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/usermenu' }} color="transparent" style={{ paddingBottom: '12px' }}>
            <IonButton>
              <IonIcon icon={returnDownBackOutline} color="white" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {queryAccessList.isLoading && (
          <IonCard>
            <IonCardContent>
              <IonItem lines="none">
                Loading...
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}


        <div style={{ paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
          <IonCard>

            <IonCardHeader>
              <IonItem lines="none" onClick={
                () => {
                  setShowModalAdd(true);
                }
              }>
              <IonBadge color="primary" slot="end"><IonIcon icon={personAddOutline} color="white" size="large" /></IonBadge>
             </IonItem>
              <hr style={{ width: "100%", borderBottom: "1px solid grey" }} />
            </IonCardHeader>


            <IonCardContent>
          <IonList>
            {queryAccessList.isSuccess && queryAccessList.data != null && queryAccessList.data.map((dataString: any) =>
            (
              <>
                

                <IonItem key={dataString.username} href="javascript:void(0);">
                  <IonIcon icon={personOutline} color={dataString.userrights === "A" ? "danger" : "success"} size="large" slot="start" />
                  <IonItemGroup>
                  
                  <IonLabel>{dataString.username} </IonLabel>
                  <IonLabel>{dataString.usertitle} </IonLabel>
                  </IonItemGroup>

                  <div className="select" slot="end"> 
                    <select id="fRights" value={dataString.userrights}
                      onChange={async (e) => {

                        const response = {
                          userid: dataString.userid,
                          userrights: e.target.value
                        }

                        const returnValue = await UpdateAppAccess(response);

                        if (returnValue == "success") {

                          present({
                            header: "Attention!",
                            message: "You have successfully update the user rights!",
                            buttons: [
                              {
                                text: "Ok", handler: async () => {
                                  window.location.href = "/tabs/useraccess/";
                                }
                              }
                            ]
                          })
 
                        }
                        else {
                          present("Sorry that we are unable to create your profile due to the following error:" + returnValue);
                        }



                        
                      }
                      }
                    >

                      
                      <option value="A">Administrator</option>
                      <option value="R">Read-Only</option>
                      <option value="" >Remove Access</option>
                    </select>
                    <span className="focus"></span>
                  </div>

                  
                  </IonItem>
                  
              </>
            ))
            }
              </IonList>
              </IonCardContent>
          </IonCard>
        </div>
      </IonContent>

      <IonModal isOpen={showModalAdd} backdropDismiss={false} onDidDismiss={() => { setShowModalAdd(false); queryAccessList.refetch();}}>
        <ModalAdd   
          qSearchList={qSearchList} showWord={showWord} setShowWord={setShowWord}  setShowModalAdd={setShowModalAdd} ></ModalAdd>
      </IonModal>



    </IonPage>
  )
}



export default UserAccess;
