import React, { useEffect, useReducer, useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonButton, IonIcon, IonInput, IonButtons } from '@ionic/react';
import { Formik } from 'formik';
import { closeOutline } from 'ionicons/icons';
import { useQuery } from 'react-query';
import { UpdateAppSetting } from '../data/api';
import './Generic.css';




interface ModalSettingProps {
  pindex: any,
  pbcname: any,
  pbcmembers: any,
  pbctermlen: any,
  pbctermlimt: any,
  pbcaptby: any,
  pbcemailyn: any,
  pbcstatus0 :any,
  setShowModalSetting: any
}

const ModalSetting: React.FC<ModalSettingProps> = ({ pindex, pbcname, pbcmembers, pbctermlen, pbctermlimt, pbcaptby, pbcemailyn, pbcstatus0,  setShowModalSetting }) => {

 

  return (<>
    <IonHeader >
      <IonToolbar color="tertiary">
        <IonTitle color="white">{pbcname}</IonTitle>
        <IonButtons slot="end" onClick={() => { setShowModalSetting(false); }} color="transparent">

          <IonButton slot="end">
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">

    
          
            <Formik
              initialValues={{
                bcmembers: pbcmembers,
                bctermlen: pbctermlen,
                bcaptby: pbcaptby,
                bcemailyn: pbcemailyn,
                bcstatus0: pbcstatus0
              }}

        onSubmit={async values => {

          const response = {    
            bcindex: pindex,
            bcmembers: values.bcmembers,
            bctermlen: values.bctermlen,
            bcaptby: String(values.bcaptby),
            bcemailyn: String(values.bcemailyn),
            bcstatus0: values.bcstatus0
          }

          var returnValue = UpdateAppSetting(response);
          await new Promise(resolve => setTimeout(resolve, 200));

               
         setShowModalSetting(false);

         }}
            >

        {(formikProps: any) => (
          <form
            onSubmit={formikProps.handleSubmit}
            style={{ paddingBottom: "5px" }}
          >
            <IonItem>
              <IonLabel slot="start">Max # of Members</IonLabel>
              <IonInput
                className="AlignRight"
                slot="end"
                type="number"
                min="0"
                max="99"
                name="bcmembers"
                value={formikProps.values.bcmembers}
                onIonChange={formikProps.handleChange}
              />
            </IonItem>

            <IonItem>
              <IonLabel slot="start">Length of Term (Year)</IonLabel>
              <IonInput
                className="AlignRight"
                slot="end"
                type="number"
                min="0"
                max="10"
                name="bctermlen"
                value={formikProps.values.bctermlen}
                onIonChange={formikProps.handleChange}
              />
            </IonItem>

        

            <IonItem>
              <IonLabel>Appointed By</IonLabel>
              <div className="select">
                <select id="bcaptby"
                  onChange={(e) => {
                    formikProps.values.bcaptby = e.target.value;
                    formikProps.handleChange(e);
                  }
                  }
                  value={formikProps.values.bcaptby}  >

                  <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                  <option value="Mayor">Mayor</option>
                  <option value="City Manager">City Manager</option>
                </select>
                <span className="focus"></span>
              </div>

            </IonItem>

            <IonItem>
              <IonLabel>Receiving Applicaction Notification</IonLabel>
              <div className="select">
                <select id="bcemailyn"
                  onChange={(e) => {
                    formikProps.values.bcemailyn = e.target.value;
                    formikProps.handleChange(e);
                  }
                  }
                  value={formikProps.values.bcemailyn}  >

                  <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
                <span className="focus"></span>
              </div>

            </IonItem>


            <IonItem>
              <IonLabel>Status</IonLabel>
              <div className="select">
                <select id="bcstatus0"
                  onChange={(e) => {
                    formikProps.values.bcstatus0 = e.target.value;
                    formikProps.handleChange(e);
                  }
                  }
                  value={formikProps.values.bcstatus0}  >

                  <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                  <option value="A">Active</option>
                  <option value="D">Inactive</option>
                </select>
                <span className="focus"></span>
              </div>

            </IonItem>

            <p style={{ height: "0px" }}></p>
            <IonButton
              color="primary"
              expand="block"
              type="submit"
              mode="ios"
            > Save Changes</IonButton>


          </form>
        )}

            </Formik>

    </IonContent>
  </>);
}




export default ModalSetting;

