import React, { useCallback, useMemo } from 'react';
import './Generic.css';
import { useDropzone } from 'react-dropzone';
import { UploadAppDoc, UploadAppDoc2 } from '../data/api';
import useAuth from '../data/useAuth';
import { GetDailyCode } from '../data/utilities';
import { useIonLoading } from '@ionic/react';

interface ModalAddProps {
  useremail: any,
  setRefreshFiles : any,
}





const ModalUpload: React.FC<ModalAddProps> = ({ useremail, setRefreshFiles }) => {

  const { user, setUser } = useAuth();
  const [present, dismiss] = useIonLoading();

  const onDrop = useCallback(acceptedFiles => {
    // Here hand the file uploading
     //console.log(acceptedFiles);
    //Loop through the file
    acceptedFiles.forEach(f => {

      let source = 0;
      const formData = new FormData();
      if (user === null || user === '' || user === false) {
        formData.append("dailycode", GetDailyCode(useremail));
        formData.append("user", '');
      }
      else {
        formData.append("dailycode", "");
        formData.append("user", user);
        source = 1;
      }
      formData.append("email", useremail);

      let myPromise = new Promise(function (myResolve, myReject) {
        if (source === 0) {
          
          formData.append("fileName", f.name);
          formData.append("formFile", f);

          UploadAppDoc(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));
        }  
        else
        {
          formData.append("fileName", f.name);
          formData.append("formFile", f);
          UploadAppDoc2(formData).then(() => myResolve("Ok")).catch((err) => myReject("Error:" + err));
        }
         
      });

      // console.log(f.name);

     }
    )
    setRefreshFiles(true);
    // present("please wait",  100, "dots");

  }, []);


  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, application/pdf'
  });



  const baseStyle = {
    display: 'flex',
    width:'100%',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  return (<>
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragAccept && (<p>All files will be accepted</p>)}
      {isDragReject && (<p>Some files will be rejected</p>)}
      {!isDragActive && (<p>Drop some files here ...</p>)}
      <div>Drag and drop your images here.</div>
    </div>
  </>);
}

export default ModalUpload;

